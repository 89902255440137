import React from 'react';
import { useModelContext } from "../../context/ModelContext";
import Logger from "../../common/Logger";
import { ItemProps } from '../../types/Item';
import { RatingMeasureProps } from '../../types/RatingMeasure';
import { RatingValueProps } from '../../types/RatingValue';

const logger = new Logger("component.ModelJson");

const ModelJson = (props:any) => {
  const state = useModelContext();

  const properties:string[] = ItemProps.ALL.concat(
    RatingMeasureProps.ALL,
    RatingValueProps.ALL,
  );

  logger.trace("Rendering:", properties, state);

  return (
    <div className="m-2">
      <h4>JSON Data View - {state.model.size()} items</h4>
      <pre className="mt-2">
        { JSON.stringify(state.model.getItems(), properties, 2) }
      </pre>
    </div>
  );
}

export default ModelJson;
