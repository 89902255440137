import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { NivoOptions } from "./Nivo";

function NivoBar(props) {
  const { data, keys, indexBy, 
          enableLabel,
          formatValue, formatTooltip, formatYAxis,
          gridYValues, //gridXValues, 
          groupMode, layout,
        } = props;

  const marginLeft = enableLabel ? 50 : 80;

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 10, right: 100, bottom: 50, left: marginLeft }}
      padding={0.3}
      theme={NivoOptions.theme}
      colors={NivoOptions.colors}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      groupMode={groupMode}
      layout={layout}
      enableLabel={enableLabel}
      enableGridY={true}
      enableGridX={false}
      gridYValues={gridYValues}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Period",
        // legendPosition: 'middle',
        // legendOffset: 32
      }}
      axisLeft={{
        format: formatYAxis,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'Value',
        // legendPosition: 'middle',
        // legendOffset: -40
      }}
      label={formatValue}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 140,
          translateY: 0,
          itemsSpacing: 2,
          itemTextColor: NivoOptions.theme.textColor,
          itemWidth: 130,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            { on: 'hover', style: { itemOpacity: 1 }
            }
          ]
        }
      ]}
      tooltipFormat={value => formatTooltip(value)}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  )
}

export default NivoBar;