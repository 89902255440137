import React from 'react';
import Logger from "../../common/Logger";
import DropdownBase from '../control/DropdownBase';
import ratingCalcs from "./rating-calcs.json";

const logger = new Logger("rating.RatingCalcDropdown");

function RatingCalcDropdown(props:any) {
  const { selected, onSelect } = props;

  logger.trace("Rendering:");

  return (
    <DropdownBase selected={selected} logger={logger.name}>
      { ratingCalcs.map(calc =>
        <button className="btn-image item" onClick={(e) => onClick(e, calc)} key={calc.name}>
          {calc.name}
        </button>
      )}
    </DropdownBase>
  );

  function onClick(e:any, calc:any) {
    if (onSelect !== undefined) {
      onSelect(calc);
    }
  }
}

export default RatingCalcDropdown;