import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { NivoOptions } from "./Nivo";

function NivoPie(props) {
  const { data, formatSlice, formatTooltip } = props;

  return (
    <ResponsivePie
      data={data}
      theme={NivoOptions.theme}
      colors={NivoOptions.colors}
      margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      // radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={10}
      radialLabelsLinkHorizontalLength={10}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      sliceLabel={formatSlice}
      slicesLabelsSkipAngle={10}
      // slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltipFormat={formatTooltip}
      // legends={[
      //   {
      //     anchor: 'bottom',
      //     direction: 'row',
      //     translateY: 56,
      //     itemWidth: 80,
      //     itemHeight: 18,
      //     itemTextColor: '#999',
      //     symbolSize: 18,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000'
      //         }
      //       }
      //     ]
      //   }
      // ]}
    />
  )
}

export default NivoPie;