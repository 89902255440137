import React from 'react';
import Logger from "../../common/Logger";
import { Table } from 'reactstrap';
import { RatingCalc } from '../../types/RatingMeasure';

const logger = new Logger("rating.RatingCalcEditor");

function RatingCalcEditor(props:any) {
  const ratingCalc:RatingCalc = props.ratingCalc;
  // const onChange = props.onChange;

  logger.debug("Rendering:", ratingCalc);

  // const onChangeScaleItem = (scaleItem:RatingScaleItem, property:keyof RatingScaleItem, newValue:any) => {
  //   try {
  //     logger.debug("onChangeScaleItem: property=%s, value=", property, newValue, scaleItem);

  //     if (scaleItem !== undefined) {
  //       setProperty(scaleItem, property, newValue);
  //       onChange(ratingScale);
  //     }
  //   } catch (e) {
  //     logger.error("onChangeScaleItem: Exception caught:", e);
  //     alert(e);
  //   }
  // }

  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{ratingCalc?.name}</td>
          <td>{ratingCalc?.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default RatingCalcEditor;
