// import API from '@aws-amplify/api';
// import Amplify from "aws-amplify";
import * as dotenv from "dotenv";
import ReactDOM from 'react-dom';
import app from "./App";
// import awsconfig from "./aws-exports";
import { openWebSocket } from './jemstone/services/WebSocket';
import * as serviceWorker from './serviceWorker';

// import { withAuthenticator } from "aws-amplify-react";

dotenv.config();

// Just so we can see it
console.debug("process.env:", process.env);

// Eliminate React error regarding contenteditable
console.error = (function(_error) {
  return function(message) {
    if (typeof message !== 'string' || message.indexOf('component is `contentEditable`') === -1) {
      _error.apply(console, arguments);
    }
  };
})(console.error);

// Configure for AWS service environment
if (process.env.REACT_APP_SERVER_ENV === "AWS") {
  // Amplify.configure(awsconfig);
  // API.configure(awsconfig);
  // app = withAuthenticator(app);

  // apiEndpoint = API.endpoint("ModelAPI");

  // Open the websocket API
  try {
    openWebSocket();
  } catch (e) {
    console.error("Cannot open websocket:", e);
  }
}

// Boot the app
ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();