import React from 'react';
import Logger from "../../common/Logger";
import ContextMenuBase from './ContextMenuBase';

function DropdownBase(props:any) {
  const { children, selected, format, readonly } = props;

  const logger = new Logger(props.logger || "control.DropdownBase");

  logger.trace("Rendering: selected", selected);

  return (
    <div className="dropdown-base">
      <span>{ formatItem(selected) }</span>
      { !readonly &&
        <ContextMenuBase caret="fas fa-caret-down" placement="bottom-end" logger={logger.name}>
          { children }
        </ContextMenuBase>
      }
    </div>
  )

  function formatItem(item:any) {
    if (format !== undefined)
      return format(item);
    
    return (item && item.name) ? item.name : "Select...";
  }
}

export default DropdownBase;
