import React from 'react';
import { RatingScaleItem } from '../../types/RatingMeasure';
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';

function RatingCountBar(props:any) {
  const value = props.value;
  const counts:number[] = props.counts;
  const scale:RatingScaleItem[] = props.scale;
  const targetValue = props.targetValue || -1;
  const className = props.className || "";

  // Index of current value
  const valueIndex = RatingMeasureUtils.findScaleItemIndex(scale, value);
  const targetIndex = RatingMeasureUtils.findScaleItemIndex(scale, targetValue);

  // Total of all counts for percentage calc
  let total = 0;
  if (counts !== undefined) {
    for (let i=0; i < counts.length; i++) {
      total += counts[i];
    }
  }
  if (total === 0) {
    return <></>;
  }

  return (
    <div className="d-flex justify-content-center">
      <div className={"progress progress-marker " + className}>
        { counts.map((count,i) => {
            const width = count/total * 100;
            const style = {
              width: width + "%",
              backgroundColor: scale[i].color
            }
            const title = scale[i].name + " (" + count + "/" + total + ")";
            
            return (
              width > 0 &&
              <div key={scale[i].value} className="progress-bar progress-marker" style={style} title={title} role="progressbar">
                { i === valueIndex && 
                  <i className="far fa-circle"/>
                }
                { i === targetIndex && 
                  <i className="far fa-bullseye"/>
                }
              </div>
            )
          })
        }
      </div>
    {/* <div className="text-muted">{JSON.stringify(counts)}</div> */}
    </div>
  );
}

export default RatingCountBar;
