/**
 * Data Model Interfaces
 */
import Logger from "../common/Logger";
import { Event } from "../types/Event";
import { Item } from "../types/Item";

const logger = new Logger("services.ModelAPI");

const urlBase = process.env.REACT_APP_API_URL;

let getItemsId = 0;
let getEventsId = 0;
let postEventsId = 0;

function getUrlBase() {
  // if (process.env.RREACT_APP_SERVER_ENV === "AWS") {
  //   const apiEndpoint = API.endpoint("ModelAPI");
  //   return apiEndpoint + (urlBase || "/api");
  // }
  return urlBase;
}

/**
 * Service Methods
 */
export async function getItems(reload:boolean = false): Promise<Item[]> {
  const id = ++getItemsId;
  const start = Date.now();
  const url = getUrlBase() + (reload ? "/api/reload" : "/api/items");

  try {
    logger.info("getItems id=%d started url=%s", id, url);

    const response = await fetch(url);
    const results = await response.json();
    const duration = Date.now() - start;

    if (!response.ok) {
      logger.error("getItems id=%d failed after %d ms, url=%s, status=%d:", 
                    id, duration, url, response.status, response);
      throw Error("Error: status=" + response.status);
    }

    logger.info("getItems id=%d finished in %d ms, status=%d, %d items returned", 
                id, duration, response.status, results.length);

    return results;
  } catch (e:any) {
    logger.error("getItems id=%d, url=%s: %s", id, url, e.message);
    return [];
  }
}

export async function getEvents(from:number): Promise<Event[]> {
  const id = ++getEventsId;
  const start = Date.now();
  const url = getUrlBase() + "/api/events/" + from;

  try {
    logger.trace("getEvents id=%d started url=%s", id, url);

    const response = await fetch(url);
    const results = await response.json();
    const duration = Date.now() - start;

    if (!response.ok) {
      logger.error("getEvents id=%d failed after %d ms, status=%d, url=%s:", 
                    id, duration, response.status, url, response);
      throw Error("Error: status=" + response.status);
    }

    if (results.length > 0) {
      logger.debug("getEvents id=%d finished in %d ms, %d items returned, status=%d, url=%s", 
                  id, duration, results.length, response.status, url);
    } else {
      logger.trace("getEvents id=%d finished in %d ms, %d items returned, status=%d, url=%s", 
                  id, duration, results.length, response.status, url);
    }

    return results;
  } catch (e:any) {
    logger.error("getEvents id=%d, url=%s: %s", id, url, e.message);
    return [];
  }
}

export async function postEvents(events:Event[]) {
  const id = ++postEventsId;
  const start = Date.now();
  const url = getUrlBase() + "/api/postEvents";

  try {
    logger.info("postEvents id=%d started url=%s, %d events:", id, url, events.length, events);

    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(events),
    });
    const result = await response.text();
    const duration = Date.now() - start;

    if (!response.ok) {
      logger.error("postEvents id=%d failed after %d ms, url=%s, status=%d:", 
                    id, duration, url, response.status, events, response);
      throw Error("Error: status=" + response.status + ", result=" + result);
    }

    logger.info("postEvents id=%d finished in %d ms, status=%d, result=%s", 
                id, duration, response.status, result);

  } catch (e:any) {
    logger.error("postEvents id=%d, url=%s: %s", id, url, e.message);
  }
}
