import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import Logger from "../../common/Logger";

let popperHasFocus:boolean;

function ContextMenuBase(props: any) {
  const children = props.children;
  const className = props.className || "";
  const caret = props.caret || "far fa-ellipsis-v pt-1";
  const placement = "left-end";
  
  const logger = new Logger(props.logger || "control.ContextMenuBase");

  const [showPopper, setShowPopper] = useState(false);

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      { name: 'offset', 
        options: { offset: [0, 0] } 
      },
    ],
  });

  React.useEffect(() => {
    popperHasFocus = false;
  }, []);

  logger.trace("Rendering: showPopper=%s, popperHasFocus=%s", showPopper, popperHasFocus);

  return (
    <div className={"contextmenu d-inline " + className}>
      <button 
        ref={setReferenceElement}
        className="btn-image" 
        onClick={() => setShowPopper(!showPopper)}
        onBlur={onBlur}
        >
        <i className={caret} />
      </button>
      { showPopper && 
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover"
             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}
        >
          {children}
        </div>
      }
    </div>
  );

  function onBlur() {
    logger.trace("onBlur: showPopper=%s, popperHasFocus=%s", showPopper, popperHasFocus);
    if (!popperHasFocus) {
      setShowPopper(false);
    }
  }

  function onMouseEnter() {
    popperHasFocus = true;
    logger.trace("onMouseEnter: showPopper=%s, popperHasFocus=%s", showPopper, popperHasFocus);
  }

  function onMouseLeave() {
    popperHasFocus = false;
    logger.trace("onMouseLeave: showPopper=%s, popperHasFocus=%s", showPopper, popperHasFocus);
  }

  function onClick() {
    logger.trace("onClick: showPopper=%s, popperHasFocus=%s", showPopper, popperHasFocus);
    setShowPopper(false);
  }
}

export default ContextMenuBase;
