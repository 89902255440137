const dateFormat = require('date-format');

const Levels = { ERROR:1, WARN:2, INFO:3, LOG:4, DEBUG:5, TRACE:6 }
const LevelNames = [ "", "ERROR", "WARN", "INFO", "LOG", "DEBUG", "TRACE" ];

const DefaultLogLevel = getDefaultLogLevel();

function getDefaultLogLevel() {
  console.debug("process.env.REACT_APP_LOGGER_LEVEL = '%s'", process.env.REACT_APP_LOGGER_LEVEL);

  switch (process.env.REACT_APP_LOGGER_LEVEL) {
    case "ERROR": return Levels.ERROR;
    case "WARN":  return Levels.WARN;
    case "INFO":  return Levels.INFO;
    case "LOG":   return Levels.LOG;
    case "DEBUG": return Levels.DEBUG;
    case "TRACE": return Levels.TRACE;
    default:      return Levels.LOG;
  }
}

export class Logger {
  public readonly name:string;
  public readonly level:number;

  public static readonly Level = Levels;

  constructor(name:string, level:number = DefaultLogLevel) {
    this.name = name;
    this.level = level;

    this.trace("Logger running, level = %d", this.level);
  }
  
  private reformat(format:string, level:number) {
    return dateFormat("yyyy-MM-dd hh:mm:ss.SSS", new Date()) + " [" + LevelNames[level] + "] [" + this.name + "] " + format;
  }

  public isErrorEnabled() : boolean {
    return this.level >= Levels.ERROR;
  }

  public isWarnEnabled() : boolean {
    return this.level >= Levels.WARN;
  }

  public isInfoEnabled() : boolean {
    return this.level >= Levels.INFO;
  }

  public isLogEnabled() : boolean {
    return this.level >= Levels.LOG;
  }

  public isDebugEnabled() : boolean {
    return this.level >= Levels.DEBUG;
  }

  public isTraceEnabled() : boolean {
    return this.level >= Levels.TRACE;
  }

  public error(format:string, ...args:any[]) {
    if (this.level >= Levels.ERROR) {
      console.error(this.reformat(format, Levels.ERROR), ...args);
    }
  }

  public warn(format:string, ...args:any[]) {
    if (this.level >= Levels.WARN) {
      console.warn(this.reformat(format, Levels.WARN), ...args);
    }
  }

  public info(format:string, ...args:any[]) {
    if (this.level >= Levels.INFO) {
      console.info(this.reformat(format, Levels.INFO), ...args);
    }
  }

  public log(format:string, ...args:any[]) {
    if (this.level >= Levels.LOG) {
      console.log(this.reformat(format, Levels.LOG), ...args);
    }
  }

  public debug(format:string, ...args:any[]) {
    if (this.level >= Levels.DEBUG) {
      console.debug(this.reformat(format, Levels.DEBUG), ...args);
    }
  }

  public trace(format:string, ...args:any[]) {
    if (this.level >= Levels.TRACE) {
      console.trace(this.reformat(format, Levels.TRACE), ...args);
    }
  }
}

export default Logger;