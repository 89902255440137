import { Event } from "../types/Event";
import { Item } from "../types/Item";
import { Model } from "../types/Model";
import { ModelEventHandler } from "./ModelEventHandler";

/**
 * Factory method to create an appropriate event handler
 * @param type 
 * @param model 
 */
export function handleEvent<T extends Item>(event:Event, model:Model) {
  const handler = new ModelEventHandler<T>(model);
  handler.handle(event);
}