import React, { useState } from "react";
import Logger from "../common/Logger";
import * as ModelService from "../services/ModelService";
import { Model } from "../types/Model";
import { ModelState } from "./ModelState";

const logger = new Logger("context.ModelContext");

const initialState:ModelState = {
  model: new Model(),
  lastUpdated: Date.now(),
  codeValues: {
    businessModelCode:    "BM-0001",
    businessStrategyCode: "BS-0001",
    capabilityModelCode:  "BC-0002",
    riskModelCode:        "RK-0001",
    opModelCode:          "OM-0001",
    portfolioModelCode:   "PM-0001",
    ratingModelCode:      "RT-0001",
    typeModelCode:        "ZZ-0000"
  }
}

const ModelContext = React.createContext<ModelState>(initialState);

export function useModelContext() {
  return React.useContext(ModelContext);
}

export function ModelContextProvider(props:any) {
  const [state, setState] = useState({...initialState});

  logger.debug("Initialising:", state);

  ModelService.setModelContext(state, onModelUpdated);

  // Invoke ModelService to load all model data asynchronously, passing setItems callback function
  React.useEffect(() => {
    logger.info("Loading model...");
    ModelService.loadModel();
  },[]);

  return (
    <ModelContext.Provider value={state}>
      { props.children }
    </ModelContext.Provider>
  );

  /** Callback from ModelService whenever model is updated, notably from a WebSocket message */
  function onModelUpdated() {
    setState({...state, lastUpdated: Date.now()});
  }
}