import React from "react";
import { Link } from "react-router-dom";

import logoSrc from "../assets/images/jemstone-logo.png";

function JemstoneHeader(props:any) {
  const logoStyle = { width:"13rem" }

  return (
    <div className="d-flex align-middle">
       <div className="flex-fill">
        <Link to="/">
          <img src={logoSrc} style={logoStyle} alt="" />
        </Link>
      </div>
      <div className="menu">
        <Link to="/dashboard">Dashboard</Link>
        <div className="d-none d-sm-inline">
          <Link className="ms-3" to="#features">Features</Link>
          {/* <Link className="ms-3" to="#pricing">Pricing</Link> */}
          <Link className="ms-3" to="#about">About</Link>
        </div>
      </div>
    </div>
  )
}

export default JemstoneHeader;