import React from 'react';
import { Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import { Item, ItemProps } from '../../types/Item';
import { RatingCalcMatrix, RatingMeasureProps, RatingScaleItem } from "../../types/RatingMeasure";
import { RatingMeasureHelper } from "../../types/RatingMeasureHelper";
import ItemDropdown from '../control/ItemDropdown';

const logger = new Logger("rating.RatingCalcMatrixEditor");

function RatingCalcMatrixEditor(props:any) {
  const context = useModelContext();
  const model = context.model;
  const showKey = props.showKey || false;
  const onChange = props.onChange;

  // Note that RatingMatrix is a type of RatingCalc
  const ratingHelper = new RatingMeasureHelper(model, props.item);
  const ratingCalc = ratingHelper.getMatrix();

  logger.debug("Rendering:", ratingCalc);

  // Some constants used to render
  const axisOptions = ratingHelper.getMatrixAxisOptions();
  const vscale = ratingHelper.getScale().scale;
  const xscale = ratingHelper.getMatrixXScaleItems();
  const yscale = ratingHelper.getMatrixYScaleItems();
  const itemKeys = [ItemProps.key, ItemProps.Name];
  const searchKeys = [RatingMeasureProps.Name, RatingMeasureProps.value];
  const styleCell = { width: 100/xscale.length + "%" }

  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th rowSpan={2} className="align-top">
            <ItemDropdown
              options={axisOptions}
              selected={model.getItem(ratingCalc.yaxisKey)}
              searchKeys={itemKeys}
              format={formatItem}
              onChange={(selectedItem:Item) => onChangeAxis(ratingCalc, RatingMeasureProps.yaxisKey, selectedItem.key)}
              className="text-left text-wrap" />
          </th>
          <th colSpan={xscale.length} className="align-content-center">
            <ItemDropdown
              options={axisOptions}
              selected={model.getItem(ratingCalc.xaxisKey)}
              searchKeys={itemKeys}
              format={formatItem}
              onChange={(selectedItem:Item) => onChangeAxis(ratingCalc, RatingMeasureProps.xaxisKey, selectedItem.key)}
              className="text-wrap" />
          </th>
        </tr>
        <tr>
          { ratingHelper.sortAscending(xscale).map(col => 
            <th className="text-wrap text-center" style={styleCell} key={col.value}>{col.name}</th>
          )}
        </tr>
      </thead>
      <tbody>
        { ratingHelper.sortDescending(yscale).map(yitem => 
          <tr className="thead-light" key={yitem.value}>
            <th scope="row">{yitem.name}</th>
            { ratingHelper.sortAscending(xscale).map(xitem =>
              <td className="matrix-pill" key={xitem.value} >
                <ItemDropdown
                  options={vscale}
                  selected={ratingHelper.getMatrixScaleItem(xitem, yitem)}
                  searchKeys={searchKeys}
                  format={formatScaleItem}
                  onChange={(selectedItem:RatingScaleItem) => onChangeValue(xitem, yitem, selectedItem.value)}
                  className="text-center text-wrap" />
              </td>
            )}
          </tr>
      )}
      </tbody>
    </Table>
  );

  function onChangeAxis(ratingCalc:RatingCalcMatrix, property:keyof RatingCalcMatrix, value:any) {
    logger.debug("onChangeMatrix started: property=%s, value=", property, value);

    // Create new rating calc
    let newRatingCalc;
    switch (property) {
      case RatingMeasureProps.xaxisKey:
        newRatingCalc = ratingHelper.newRatingCalcMatrix(ratingCalc, value, ratingCalc.yaxisKey);
        break;

      case RatingMeasureProps.yaxisKey:
        newRatingCalc = ratingHelper.newRatingCalcMatrix(ratingCalc, ratingCalc.xaxisKey, value);
        break;
    }

    // Escalate the change
    onChange(newRatingCalc);
  }

  // Called when a value in the matrix changes
  function onChangeValue(xitem:RatingScaleItem, yitem:RatingScaleItem, value:number) {
    const x = xscale.findIndex(si => si.value === xitem.value);
    const y = yscale.findIndex(si => si.value === yitem.value);

    const newRatingCalc:RatingCalcMatrix = {...ratingCalc};
    newRatingCalc.values[y][x] = value;

    // Escalate the change
    onChange(newRatingCalc);
  }

  function formatItem(item:Item | undefined) {
    return (item === undefined) ? "" : showKey ? (item.key + " " + item.name) : item.name;
  }

  function formatScaleItem(scaleItem:RatingScaleItem) {
    return (scaleItem === null || scaleItem === undefined) ? "" : scaleItem.name;
  }
}

export default RatingCalcMatrixEditor;