import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import { useViewContext } from '../../context/ViewContext';
import { DocumentLink } from '../../views/ViewLinks';
import RatingAssessment from '../rating/RatingAssessment';

const logger = new Logger("cards.RatingValueCard");

function RatingValueCard(props: any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const item = props.item;
  const onEvent = props.onEvent;

  logger.debug("Rendering: key=%s", item.key, item);

  const itemType = model.getItemType(item);
  const ratingPeriodFolder = model.getRatingPeriodFolder();

  // Render a Document view
  if (props.docView) return (
    <section>
      <h2>Rating Value</h2>
      <Narrative className="text-muted" />
      <div className="mt-2">
        <RatingAssessment item={item} onEvent={onEvent} />
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className="shadow text-level-2">
      <CardHeader>
        <h2>Rating Value</h2>
      </CardHeader>
      <CardBody className="p-0">
        <RatingAssessment item={item} onEvent={onEvent} />
      </CardBody>
    </Card>
  );

  function Narrative(props:any) {
    const className = props.className || "";

    const measure = model.getMeasure(item);
    if (measure === undefined) return (
      <div className={className}>
        Edit the <DocumentLink item={itemType}/> type definition to define a Rating Measure 
        for this <DocumentLink item={itemType}/>.
      </div>
    )
    return (
      viewState.showCountBar
      ? <div className={className}>
          This table illustrates the number of child <DocumentLink item={itemType}/>'s with each score
          over all <DocumentLink item={ratingPeriodFolder}/>.
        </div>
      : <div className={className}>
          This table illustrates the {getCalc()} score of rated child <DocumentLink item={itemType}/>'s
          over all <DocumentLink item={ratingPeriodFolder}/>.
        </div>
    )

    function getCalc() {
      switch (measure?.ratingCalc?.key) {
        case "AVG":    return "average";
        case "MIN":    return "minimum";
        case "MAX":    return "maximum";
        case "TOTAL":  return "total";
        case "MATRIX": return "matrix";
      }
    }
  }
}

export default RatingValueCard;
