import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import * as ModelService from "../../services/ModelService";
import { RatingCalc, RatingMeasure, RatingMeasureProps } from '../../types/RatingMeasure';
import { RatingMeasureHelper } from '../../types/RatingMeasureHelper';
import RatingCalcDropdown from '../rating/RatingCalcDropdown';
import RatingCalcEditor from '../rating/RatingCalcEditor';
import RatingCalcMatrixEditor from '../rating/RatingCalcMatrixEditor';

const logger = new Logger("cards.RatingCalcCard");

function RatingCalcCard(props: any) {
  const state = useModelContext();
  const className = props.className;
  const onEvent = props.onEvent;

  const measure:RatingMeasure = props.item;

  logger.debug("Rendering:", measure);

  // Render a Document view
  if (props.docView) return (
    <section className={className}>
      <h2>
        Calculation Rule: <RatingCalcDropdown selected={measure.ratingCalc} onSelect={onSelectCalc} />
      </h2>
      <div className="mt-1">
        { measure.ratingCalc?.key === "MATRIX"
          ? <RatingCalcMatrixEditor item={measure} onChange={onChangeCalc} />
          : <RatingCalcEditor ratingCalc={measure.ratingCalc} onChange={onChangeCalc} />
        }
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className={className + " shadow text-level-2"}>
      <CardHeader className="border-0">
        <h2>
          Calculation Rule: 
          <RatingCalcDropdown selected={measure.ratingCalc} onSelect={onSelectCalc} />
        </h2>
      </CardHeader>
      <CardBody className="p-0">
        { measure.ratingCalc?.key === "MATRIX"
          ? <RatingCalcMatrixEditor item={measure} onChange={onChangeCalc} />
          : <RatingCalcEditor ratingCalc={measure.ratingCalc} onChange={onChangeCalc} />
        }
      </CardBody>
    </Card>
  );

  function onSelectCalc(calc: RatingCalc) {
    logger.debug("onSelectCalc: calc:", calc);

    try {
      const newCalc = new RatingMeasureHelper(state.model, measure).newRatingCalc(calc);
      onChangeCalc(newCalc);
    } catch (e) {
      alert("You cannot create this type of RatingCalc for this item");
    }
  }

  function onChangeCalc(calc: RatingCalc) {
    logger.debug("onChangeCalc started: calc:", calc);

    const event = ModelService.updateItems<RatingMeasure>(state.model, [measure.key], RatingMeasureProps.ratingCalc, calc);
    if (onEvent !== undefined) {
      onEvent(event);
    }

    logger.debug("onChangeCalc finished:", event);
  }
}

export default RatingCalcCard;