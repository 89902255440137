import React from 'react';
import GaugeChart from 'react-gauge-chart';
import Logger from '../../common/Logger';
import { useModelContext } from '../../context/ModelContext';
import { Item } from '../../types/Item';
import { DocumentLink } from '../../views/ViewLinks';
import { ScaleDataProvider } from './DataProvider';

const logger = new Logger("rating.RatingChartGauge");

/**
 * Display the RatingValue's for specified items using a radar chart
 */
export function RatingChartGauge(props:any) {
  const model = useModelContext().model;
  const { items, measure, ratingPeriod, classRow } = props;

  if (!items || items.length === 0) {
    return <></>;
  }

  logger.debug("Rendering: measure, items, period:", measure, items, ratingPeriod)

  const ratingPeriods = ratingPeriod ? [ratingPeriod] : model.getRatingPeriods();

  return (
    <div className="">
      <div className={classRow}>
        { ratingPeriods.map(period =>
          <div className="col text-center text-muted h4">{period.name}</div>
        )}
      </div>
      { items.map((item:Item) =>
        <div className={classRow}>
          { ratingPeriods.map(period =>
            <div className="col p-1">
              <Gauge item={item} period={period} />
              <div className="text-center text-level-4">
                <DocumentLink item={item} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  function Gauge(props:any) {
    const { item, period } = props;
    const series = new ScaleDataProvider(model, measure, item, period);

    return (
      <GaugeChart 
        id={"gauge-" + item.key + "-" + period.key}
        percent={series.currentValue / series.maxValue}
        hideText={true}
        arcsLength={series.percents}
        colors={series.colors}
        className="gauge" 
      />
    )
  }
}
