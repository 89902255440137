import React from 'react';
import Logger from "../../common/Logger";
import { isDark } from '../../common/utils';
import { Item } from '../../types/Item';
import ContextMenuBase from './ContextMenuBase';

const logger = new Logger("control.ItemDropdown");

function ItemDropdown(props:any) {
  const { options, selected, onChange, readonly } = props;
  const caret = props.caret || "fas fa-caret-down";
  const format = props.format || formatItem;
  let className = props.className || "";

  logger.trace("Rendering: selected", selected);

  /**
   * This is a bit messy, but if the item to be display has a 'color' property, then use this for
   * the background color and change the text color for readability if necessary
   */
  let styleBackground = {};
  if (selected && selected.color) {
    styleBackground = { backgroundColor: selected.color }
    className += (isDark(selected.color) ? " light" : "");
  }

  return (
    <div className={"dropdown-base " + className} style={styleBackground}>
      <span>{format(selected)}</span>
      { !readonly &&
        <ContextMenuBase caret={caret} placement="bottom-end" logger={logger.name}>
          { options.map((item:Item,index:number) =>
            <button className="btn-image item" onClick={(e) => onClick(e, item)} key={index}>
              { format(item) }
            </button>
          )}
        </ContextMenuBase>
      }
    </div>
  )

  function onClick(e:any, item:any) {
    if (onChange !== undefined) {
      onChange(item);
    }
  }

  function formatItem(item:Item | undefined) {
    return (item === undefined) ? "" : item.name;
  }
}

export default ItemDropdown;
