import React from 'react';
import GaugeChart from 'react-gauge-chart';
import Logger from '../../common/Logger';
import { getRandomInt } from "../../common/utils";
import { useModelContext } from '../../context/ModelContext';
import { useViewContext } from '../../context/ViewContext';
import { RatingScaleItem } from '../../types/RatingMeasure';
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';
import RatingCountBar from './RatingCountBar';
import RatingScaleBar from './RatingScaleBar';

const logger = new Logger("rating.RatingValueGadget");

/**
 * Display a RatingValue for specified item using a progress or a gauge style gadget
 * @param props.item The rateable Item that whose value will be displayed
 * @param props.date The date used to determine the RatingPeriod
 * @param props.showGauge
 * @param props.showCountBar
 * @param props.showScaleBar
 */
function RatingValueGadget(props:any) {
  const context = useModelContext();
  const viewState = useViewContext();
  const model = context.model;
  const item = props.item;
  const date:number = props.date || Date.now();
  const showGauge = props.showGauge;
  const showCountBar = props.showCountBar || true;
  const showScaleBar = props.showScaleBar || true;
  const className = props.className || "";

  const ratingModel = model.getRatingModel();

  // RatingMeasure is the first for this item
  const measure = props.measure || model.getMeasure(item);
  if (!measure || !RatingMeasureUtils.hasScaleItems(measure)) {
    logger.trace("Cannot find RatingMeasure for item:", item);
    return <></>;
  }

  // RatingPeriod for current date
  const ratingPeriod = model.getRatingPeriodForDate(date);
  if (ratingPeriod === undefined) {
    logger.trace("Cannot find RatingPeriod for current date=%d", date, model.getRatingPeriods());
    return <></>;
  }

  // RatingValue for specified itemKey, measureKey, periodKey
  const ratingValue = ratingModel.getValue(item.key, measure.key, ratingPeriod.key);
  if (ratingValue === undefined) {
    return <></>;
  }

  // Get target value for measure
  const ratingPeriods = model.getRatingPeriods();
  const targetPeriod = ratingPeriods[ratingPeriods.length-1];
  const targetValue = ratingModel.getValue(item.key, measure.key, targetPeriod.key);

  if (!showGauge && ratingValue.value === 0 && 
     (targetValue === undefined || targetValue.value === 0)) {
    return <></>;
  }

  // RatingScale from measure
  const ratingScale = measure.ratingScale;
  const scale:RatingScaleItem[] = ratingScale.scale;
  const maxValue = scale[scale.length-1].value;
  const ratingCounts = ratingModel.getCounts(ratingValue);

  // Color comes from the RatingScaleItem for the value
  const scaleItem = RatingMeasureUtils.findScaleItem(scale, ratingValue.value);
  const name  = (scaleItem !== undefined) ? scaleItem.name : "";

  // If using a gauge then some more work to do
  const chartData = getChartData(ratingCounts);

  logger.trace("Rendering: showGauge=%s, showCountBar=%s, showScaleBar=%s, key=%s, value=%f, max=%f", 
                showGauge, viewState.showCountBar, showScaleBar, item.name, ratingValue.value, maxValue);
 
  return (
    <div className={"gauge" + className}>
      { showGauge && viewState.showCountBar &&
        <GaugeChart id={"gauge-" + getRandomInt(1,100000)} 
                    hideText={true}
                    needleColor="transparent"
                    needleBaseColor="transparent"
                    arcsLength={chartData.countArcs}
                    colors={chartData.colors}
                    className="gauge-chart" />
      }
      { showGauge && !viewState.showCountBar &&
        <GaugeChart id={"gauge-" + getRandomInt(1,100000)} 
                    percent={ratingValue.value/maxValue}
                    hideText={true}
                    arcsLength={chartData.valueArcs}
                    colors={chartData.colors}
                    className="gauge-chart" />
      }
      { showCountBar && !showGauge && ratingCounts && viewState.showCountBar &&
        <RatingCountBar key={"count-" + item.key} 
                        name={name}
                        scale={scale}
                        value={ratingValue.value} 
                        // targetValue={targetValue.value} 
                        counts={ratingCounts} />
      }
      { showScaleBar && !showGauge && !viewState.showCountBar &&
        <RatingScaleBar key={"value-" + item.key} 
                        name={name} 
                        scale={scale}
                        value={ratingValue.value} 
                        targetValue={targetValue !== undefined ? targetValue.value : 0} 
                        className={showCountBar ? "" : ""} />
      }
    </div>
  );

  function getChartData(rc:any) {
    const names:string[] = [];
    const colors:string[] = [];
    const counts:number[] = [];
    const values:number[] = [];
    const valueArcs:number[] = [];
    const countArcs:number[] = [];

    let prev=0, countsTotal=0;
    scale.forEach((scaleItem, i) => {
      names.push(scaleItem.name);
      colors.push(scaleItem.color);
      values.push(scaleItem.value);
      valueArcs.push((scaleItem.value - prev) / maxValue);
      prev = scaleItem.value;

      let count = rc !== undefined ? rc[i] : 0;
      counts.push(count);
      countsTotal += count;
    });

    for (let i=0; i < counts.length;  i++) {
      countArcs[i] = counts[i] / countsTotal;
    }

    return {
      names: names,
      colors: colors,
      values: values,
      counts: counts,
      valueArcs: valueArcs,
      countArcs: countArcs,
    }
  }
}

export default RatingValueGadget;
