import React from 'react';
import Logger from '../../common/Logger';
import { useModelContext } from '../../context/ModelContext';
import { ItemSeriesProvider3 } from './DataProvider';
import NivoBump from './NivoBump';

const logger = new Logger("rating.RatingChartBump");

/**
 * Display the RatingValue's for specified items using a radar chart
 */
export function RatingChartBump(props:any) {
  const model = useModelContext().model;
  const items = props.items;
  const measure = props.measure;

  if (!items || items.length === 0) {
    return <></>;
  }
  
  const series = new ItemSeriesProvider3(model, measure, items);

  logger.debug("Rendering:", series);
 
  return (
    <div className="chart-rating">
      <NivoBump data={series.data} getValueLabel={getValueLabel} />
   </div>
  );

  function getValueLabel(value:number) : string {
    return series.getScaleItemName(value);
  }
}

