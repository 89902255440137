import React from 'react';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import { useViewContext } from '../../context/ViewContext';
import * as ModelService from '../../services/ModelService';
import { Item } from '../../types/Item';
import ContextMenuBase from './ContextMenuBase';

const logger = new Logger("control.ContextMenu");

function ContextMenu(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();

  const item = props.item;
  const onEvent = props.onEvent;
  const className = props.className || "";

  const siblings = model.childrenSorted(item?.parentKey);
  const index = siblings.findIndex(sibling => (sibling.key === item?.key));

  logger.trace("Rendering: itemKey=%s", item?.key);

  return (
    <ContextMenuBase className={className} logger="control.ContextMenu">
      <button className="btn-image item" onClick={onCut}>
        <i className="far fa-cut"></i>
        Cut
      </button>
      <button className="btn-image item" onClick={onCopy}>
        <i className="far fa-copy"></i>
        Copy
      </button>
      <button className="btn-image item" onClick={onPaste} disabled={!viewState.hasClipboardItems()}>
        <i className="far fa-paste"></i>
        Paste
      </button>
      <button className="btn-image item" onClick={onRemove}>
        <i className="far fa-trash-alt"></i>
        Delete
      </button>
      <button className="btn-image item" onClick={onCreate}>
        <i className="far fa-plus"></i>
        Create Sibling
      </button>
      <button className="btn-image item" onClick={onCreateChild}>
        <i className="far fa-layer-plus"></i>
        Create Child
      </button>
      <button className="btn-image item" onClick={() => onSort("UP")} disabled={index <= 0}>
        <i className="far fa-sort-numeric-up"></i>
        Move Up
      </button>
      <button className="btn-image item" onClick={() => onSort("DOWN")} disabled={index < 0 || index >= siblings.length}>
        <i className="far fa-sort-numeric-down"></i>
        Move Down
      </button>
      <button className="btn-image item" onClick={() => onSort("ALPHA")}>
        <i className="far fa-sort-alpha-down"></i>
        Sort by Name
      </button>
    </ContextMenuBase>
  );

  function onCreate() {
    logger.debug("onCreate: parentKey=%s", item.parentKey);

    // Create a new item, with same type as it's sibling
    const newItem:Item = model.newItem(item.parentKey);
    newItem.typeKey = item.typeKey;

    const event = ModelService.createItems(model, item.parentKey, [newItem]);
    onEvent(event);
  }

  function onCreateChild() {
    logger.debug("onCreate: parentKey=%s", item.key);

    const event = ModelService.createItems(model, item.key);
    onEvent(event);
  }

  function onRemove() {
    logger.debug("onRemove: itemKey=%s", item.key);

    const event = ModelService.removeItems(model, [item.key]);
    onEvent(event);
  }

  function onCut() {
    logger.debug("onCut: itemKey=%s", item.key);

    // Put all cut items on the clipboard
    viewState.setClipboard([item]);

    const event = ModelService.removeItems(model, [item.key]);
    onEvent(event);
  }

  function onCopy() {
   logger.debug("onCopy: itemKey=%s", item.key);

    viewState.setClipboard([item]);
    onEvent({name:"onCopy", value:item.key});
  }

  function onPaste() {
    const items = viewState.getClipboard();
    logger.debug("onPaste: parentKey=%s, items:", item.parentKey, items);

    const event = ModelService.createItems(model, item.parentKey, items);
    onEvent(event);
  }

  function onSort(direction: "UP" | "DOWN" | "ALPHA") {
    const event = ModelService.sortItem(model, item.key, direction);
    onEvent(event);
  }
}

export default ContextMenu;
