import React from "react";
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { RatingMeasure } from "../../types/RatingMeasure";
import { RatingMeasureUtils } from "../../types/RatingMeasureHelper";
import { RatingPeriod, RatingValue } from "../../types/RatingValue";

const logger = new Logger("debug.DebugRatingValue");

function DebugRatingValue(props:any) {
  const model = useModelContext().model;
  const value:RatingValue = props.item;

  if (!logger.isDebugEnabled()) {
    return (
      <div className="text-muted">
        value={value.value},
        itemKey={value.itemKey}, 
        measureKey={value.measureKey}, 
        periodKey={value.periodKey}
      </div>
    )
  }
  
  const measure = model.getItem<RatingMeasure>(value.measureKey);
  const ratingPeriod = model.getItem<RatingPeriod>(value.periodKey);
  const ratingScale = measure.ratingScale.scale;
  const scaleItem = RatingMeasureUtils.findScaleItem(ratingScale, value.value);
  const counts = model.getRatingModel().getCounts(value);

  return (
    <table className="table-borderless m-0 text-muted">
      <tr>
        <td>Period:</td>
        <td>{ratingPeriod.name}</td>
        <td>{ratingPeriod.key}</td>
      </tr>
      <tr>
        <td>Rating:</td>
        <td>{measure.name}</td>
        <td>{measure.key}</td>
      </tr>
      <tr>
        <td>Value:</td>
        <td>{value.value} - {scaleItem?.name}</td>
        <td></td>
      </tr>
      <tr>
        <td>Counts:</td>
        <td colSpan={2}>{JSON.stringify(counts)}</td>
      </tr>
      <tr>
        <td>Status:</td>
        <td>{value.status}</td>
      </tr>
    </table>
  )
}

export default DebugRatingValue;