import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import * as ModelService from "../../services/ModelService";
import { Item, ItemProps } from '../../types/Item';
import { DocumentLink } from '../../views/ViewLinks';
import ItemPickerTree from '../control/ItemPickerTree';
import { ItemTypeIcon } from '../control/ItemTypeIcon';
import { NetworkGraphCard } from './NetworkGraphCard';
import { ViewContextProvider } from '../../context/ViewContext';

const logger = new Logger("cards.ItemRelationshipSection");

function ItemRelationshipSection(props: any) {
  const item = props.item;
  const onEvent = props.onEvent;
  const docView = props.docView;

  logger.debug("Rendering: key=%s", item.key, item);

  if (docView) return (
    <section>
      <h2>Related Items</h2>
      <div className="mt-1 text-muted">
        Outbound links refer to items that are referenced by {item.name}.
        Inbound links are items that reference {item.name}.
      </div>
      <div className="mt-1">
        <ItemRelationshipCards item={item} onEvent={onEvent} docView={true} />
      </div>
      <div className="mt-2">
        <NetworkGraphCard rootKey={item.key} />
      </div>
    </section>
  )

  return (
    <Card className="shadow text-level-2">
      <CardHeader>
        <h2>Related Items</h2>
        <div className="mt-1 text-muted">
          Outbound links refer to items that are referenced by {item.name}.
          Inbound links are items that reference {item.name}.
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <div className="mt-1">
          <ItemRelationshipCards item={item} onEvent={onEvent} docView={false} />
        </div>
        <div className="mt-2">
          <NetworkGraphCard rootKey={item.key} />
        </div>
      </CardBody>
    </Card>
  )
}

function ItemRelationshipCards(props:any) {
  const model = useModelContext().model;
  const [item,setItem] = useState<Item>(props.item);
  const onEvent = props.onEvent;
  const docView = props.docView;

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
      <div className="col">
        <ViewContextProvider name="RelationshipCard.Search">
          <RelationshipCard item={item} title="Outbound Links" outbound={true} />
        </ViewContextProvider>
      </div>
      <div className="col">
        <RelationshipCard item={item} title="Inbound Links" inbound={true} />
      </div>
      <div className="col">
        <RelationshipCard item={item} title="Child Links" children={true} />
      </div>
    </div>
  )

  function RelationshipCard(props:any) {
    const { item, title, inbound, outbound /*, children*/ } = props;
    const keys = getLinkedKeys();
  
    return (
      <Card className={docView ? "shadow mt-1" : ""}>
        <CardHeader className="d-flex pt-3">
          <div className="h4 flex-fill">
            {title}
          </div>
          { outbound &&
            <AddOutboundLink item={item} />
          }
        </CardHeader>
        <CardBody>
          { keys.map((key,index) => 
            <LinkedItem linkKey={key} index={index} key={key} />
          )}
        </CardBody>
      </Card>
    )

    function LinkedItem(props:any) {
      const { linkKey, index } = props;

      const linkItem = model.getItem(linkKey);

      if (outbound) return (
        <div className="d-flex text-muted">
          <div className="flex-fill">
            { linkItem &&
              <ItemTypeIcon item={linkItem}/>
            }
            <ItemPickerTree
              selected={linkItem}
              searchKeys={[ItemProps.key, ItemProps.Name]}
              onChange={(selected:Item) => onUpdateLink(index,selected)}
            />
          </div>
          <RemoveOutboundLink linkKey={linkKey} />
        </div>
      )

      if (linkItem) return (
        <div className="text-muted" key={linkItem.key}>
          <ItemTypeIcon item={linkItem}/>
          <DocumentLink item={linkItem} className="ms-1" />
        </div>
      )
      
      return (<></>)
    }

    function getLinkedKeys() : string[] {
      if (outbound) {
        return item.links || [];
      }

      // Find inbound links
      if (inbound) {
        const inboundLinks:string[] = [];
        for (const i1 of model.getItems()) {
          if (i1.links && i1.links.length > 0) {
            for (const key of i1.links) {
              if (key === item.key) {
                inboundLinks.push(i1.key);
              }
            }
          }
        }
        return inboundLinks;
      }

      return model.childrenSorted(item.key).map(item => item.key);
    }
  }

  function AddOutboundLink(props:any) {
    return (
      <button className="btn-image" onClick={() => onAddLink()}>
        <i className="far fa-plus"></i>
      </button>
    )
  }

  function RemoveOutboundLink(props:any) {
    const linkKey = props.linkKey;

    return (
      <button className="btn-image" onClick={() => onRemoveLink(linkKey)}>
        <i className="far fa-trash-alt"></i>
      </button>
    );
  }

  function onAddLink() {
    if (!item.links) {
      updateLinks([""]);
    } 
    
    else {
      const links:string[] = Array.from(item.links);
      links.push("");
      updateLinks(links);
    }
  }

  function onRemoveLink(linkKey:string) {
    if (item.links) {
      const links = item.links.filter(key => key !== linkKey);
      updateLinks(links);
    }
  }

  function onUpdateLink(index:number, link:Item) {
    logger.debug("onUpdateLink: itemKey=%s, linkKey=%s, index=%d, links:", item.key, link.key, index, item.links);

    if (item.links && item.links.length > 0) {
      const links:string[] = Array.from(item.links);
      links[index] = link.key;
      updateLinks(links);
    }
  }

  function updateLinks(links:string[] | undefined) {
    const event = ModelService.updateItems(model, [item.key], ItemProps.links, links);

    setItem(model.getItem<Item>(item.key));

    if (onEvent !== undefined) {
      onEvent(event);
    }
  }
}

export default ItemRelationshipSection;
