import React from 'react';
import { Link } from 'react-router-dom';
import { useModelContext } from "../../context/ModelContext";

export function ItemTypeIcon(props:any) {
  const model = useModelContext().model;
  const item = props.item;
  
  const itemType = model.getItemType(item) || item;
  const iconClass = itemType?.typeIcon + " btn-image";

  return (
    <div className="d-inline-block">
      <Link to={{ pathname: "/document/" + item.key }}>
        <i className={iconClass} />
      </Link>
    </div>
  )
}

export function DocumentLinkParent(props:any) {
  const item = props.item;
  const className = props.className || "ms-1"
  
  const iconClass = "far fa-angle-up btn-image " + className;

  if (item === undefined || item.parentKey === "") {
    return <></>
  }

  return (
    <div className="d-inline-block">
      <Link to={{ pathname: "/document/" + item.parentKey }}>
        <i className={iconClass} />
      </Link>
    </div>
  )
}

export function DashboardLinkParent(props:any) {
  const item = props.item;
  const className = props.className || "ms-1"
  
  const iconClass = "far fa-angle-up btn-image " + className;

  if (item === undefined) {
    return <></>
  }

  return (
    <div className="d-inline-block">
      <Link to={{ pathname: "/dashboard/" + item.parentKey }}>
        <i className={iconClass} />
      </Link>
    </div>
  )
}

export function CanvasLinkParent(props:any) {
  const item = props.item;
  const className = props.className || "ms-1"
  
  const iconClass = "far fa-angle-up btn-image " + className;

  if (item === undefined || item.parentKey === "") {
    return <></>
  }

  return (
    <div className="d-inline-block">
      <Link to={{ pathname: "/canvas/" + item.parentKey }}>
        <i className={iconClass} />
      </Link>
    </div>
  )
}

export function NetworkLinkParent(props:any) {
  const item = props.item;
  const className = props.className || "ms-1"
  
  const iconClass = "far fa-angle-up btn-image " + className;

  if (item === undefined || item.parentKey === "") {
    return <></>
  }

  return (
    <div className="d-inline-block">
      <Link to={{ pathname: "/network/" + item.parentKey }}>
        <i className={iconClass} />
      </Link>
    </div>
  )
}
