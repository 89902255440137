import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Logger from "../common/Logger";
import HomePage from "../views/HomePage";
import JemstoneHomeHeader from "./JemstoneHomeHeader";

const logger = new Logger("layout.JemstoneHomeLayout");

function JemstoneHomeLayout(props:any) {
  logger.trace("Rendering:", props);

  return (
    <div className="jemstone-body">
      <div>
        <div className="jemstone-header p-3 bg-transparent">
          <JemstoneHomeHeader />
        </div>
        <div className="jemstone-content m-0 p-0">
          <Switch>
            <Route path="/home" component={ withRouter(HomePage) } />
            <Redirect from="*" to="/home" />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default JemstoneHomeLayout;