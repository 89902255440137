import numbro from 'numbro';
import React, { useState } from "react";
import Logger from "../../common/Logger";
import { formatNumber } from "../../common/utils";
import { useModelContext } from "../../context/ModelContext";
import * as ModelService from "../../services/ModelService";
import { Item } from "../../types/Item";

const logger = new Logger("component.Editable");

// Create an editable cell renderer
function Editable(props:any) {
  const state = useModelContext();
  const className = props.className || "";
  const style = props.style;
  const item:Item = props.item;
  const property:keyof Item = props.property;
  const onEvent = props.onEvent;
  const isNumeric = props.numeric || false;
  const numberFormat = props.numberFormat;
  const placeholder = props.placeholder;
  const onChange = props.onChange || onChangeItem;

  const [value, setValue] = useState(item[property]);

  logger.trace("Rendering field: %s='%s'", property, value, item);

  // If the property value is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(item[property]);
  }, [item, property]);

  // Default is to display placeholder text, props.placeholder can override this
  let placeholderText;
  if (placeholder === undefined || placeholder === true) {
    placeholderText = "Enter " + property + "...";
  } else if (typeof placeholder === "string") {
    placeholderText = placeholder;
  }

  return (
    <div contentEditable={true} onBlur={onBlur} placeholder={placeholderText}
         className={"editable " + className} style={style} >
      { isNumeric ? formatNumber(value, numberFormat) : value }
    </div>
  )

  // Default onChange handler for updating Model Items
  function onChangeItem(item:Item, property:keyof Item, newValue:string|number) {
    try {
      setValue(newValue);

      const event = ModelService.updateItems(state.model, [item.key], property, newValue);
      if (onEvent !== undefined) {
        logger.debug("onChangeItem: calling onEvent:", event);
        onEvent(event);
      }
      return event;
    } catch (e) {
      logger.error("onChangeItem: Exception caught:", e);
      alert(e);
    }
  }

  // We'll only update the external data when the input is blurred
  function onBlur(e:any) {
    let newValue = e.target.innerText;
    if (isNumeric) {
      newValue = numbro.unformat(newValue);
    }

    if (newValue !== value) {
      logger.debug("onBlur: Value changed: property=%s, value='%s', newValue='%s'",
                    property, value, newValue, item);

      onChange(item, property, newValue);
    }
  }
}

export default Editable;
