export enum ItemStatus { 
  NEW = "New", 
  MODIFIED = "Modified", 
  DELETED = "Deleted",
  TRANSIENT = "Transient"
}

export interface Item {
  key: string;
  code?: string;
  name: string;
  description: string;
  parentKey: string;
  typeKey: string;
  sortOrder: number;
  links?: string[];
  status: ItemStatus;
  modifiedDate: number;
  modifiedBy?: string;
}

export type ItemProp = keyof Item;

export class ItemProps {
  static key:ItemProp = "key";
  static code:ItemProp = "code";
  static Name:ItemProp = "name";
  static description:ItemProp = "description";
  static parentKey:ItemProp = "parentKey";
  static typeKey:ItemProp = "typeKey";
  static sortOrder:ItemProp = "sortOrder";
  static links:ItemProp = "links";
  static status:ItemProp = "status";
  static modifiedDate:ItemProp = "modifiedDate";
  static modifiedBy:ItemProp = "modifiedBy";

  static ALL:string[] = [
    ItemProps.key,
    ItemProps.code,
    ItemProps.Name,
    ItemProps.description,
    ItemProps.parentKey,
    ItemProps.typeKey,
    ItemProps.sortOrder,
    ItemProps.links,
    ItemProps.status,
    ItemProps.modifiedDate,
    ItemProps.modifiedBy
  ]
}