import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Logger from "../common/Logger";
import NetworkGraph from "../components/chart/NetworkGraph";
import Editable from "../components/control/Editable";
import { ItemTypeIcon, NetworkLinkParent } from "../components/control/ItemTypeIcon";
import ToolbarAction from "../components/control/ToolbarAction";
import { useModelContext } from "../context/ModelContext";
import { useViewContext, ViewContextProvider } from "../context/ViewContext";
import { ItemProps } from "../types/Item";

const logger = new Logger("view.NetworkView");

function NetworkView(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const { key } = (useParams() as any);
  const { pathname } = useLocation();
  const rootKey = key || "";
  const rootItem = model.getItem(rootKey);

  // This will sync the sidebar
  React.useEffect(() => {
    if (model.has(rootKey)) {
      viewState.openTo(model, rootKey);
      viewState.onEvent({ name:"NetworkView", key:rootKey });
    }
  }, [pathname]);
  
  if (model.isEmpty()) {
    return <></>
  }

  logger.debug("Rendering: rootKey='%s'", rootKey, props);

  return (
    <ViewContextProvider name="NetworkView" rootKey={rootKey} level={2}>
      <Card className="jemstone-view shadow">
        <CardHeader className="">
          <div className="text-nowrap d-flex align-middle">
            <div className="h1 flex-fill">
              {rootItem
                ? <>
                  <ItemTypeIcon item={rootItem} />
                  <Editable item={rootItem} property={ItemProps.Name} className="d-inline ms-1 me-1" />
                  <NetworkLinkParent item={rootItem} />
                </>
                : <>
                  <i className="fal fa-chart-network btn-image me-1" />
                  <span>Relationship View</span>
                </>
              }
            </div>
            <ToolbarAction rootKey={rootKey} docView={true} showExpandAll={true} showRefresh={true} />
          </div>
        </CardHeader>
        <CardBody className="">
          <div className="ratio ratio-1x1">
            <NetworkGraph animation={true} rootKey={rootKey} level={2} />
          </div>
        </CardBody>
      </Card>
    </ViewContextProvider>
  );
}

export default NetworkView;
