import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import * as ModelService from "../../services/ModelService";
import { RatingMeasure, RatingMeasureProps, RatingScale } from '../../types/RatingMeasure';
import { RatingMeasureHelper, RatingMeasureUtils } from '../../types/RatingMeasureHelper';
import RatingScaleDropdown from '../rating/RatingScaleDropdown';
import RatingScaleEditor from '../rating/RatingScaleEditor';

const logger = new Logger("cards.RatingScaleCard");

function RatingScaleCard(props: any) {
  const state = useModelContext();
  const className = props.className || "";
  const onEvent = props.onEvent;

  const measure:RatingMeasure = props.item;

  logger.debug("Rendering:", measure);

  // Render a Document view
  if (props.docView) return (
    <section className={className}>
      <h2>
        Rating Scale: <RatingScaleDropdown selected={measure.ratingScale} onSelect={onSelectScale} />
      </h2>
      <div className="mt-1">
        <ScaleEditor ratingScale={measure.ratingScale} onChange={onChangeScale} />
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className={className + " shadow text-level-2"}>
      <CardHeader className="border-0">
        <h2>
          Rating Scale: <RatingScaleDropdown selected={measure.ratingScale} onSelect={onSelectScale} />
        </h2>
      </CardHeader>
      <CardBody className="p-0">
        <ScaleEditor ratingScale={measure.ratingScale} onChange={onChangeScale} />
      </CardBody>
    </Card>
  );

  function ScaleEditor(props:any) {
    return (
      RatingMeasureUtils.hasScaleItems(measure)
      ? <RatingScaleEditor ratingScale={measure.ratingScale} onChange={onChangeScale} />
      : <div className="text-muted">
          The {measure.ratingScale?.name} scale allows the entry of any numeric value
        </div>
    )
  }

  function onSelectScale(scale: RatingScale) {
    logger.debug("onSelectScale: calc:", scale);

    try {
      const newScale = new RatingMeasureHelper(state.model, measure).newRatingScale(scale);
      onChangeScale(newScale);
    } catch (e) {
      alert("You cannot create this type of RatingScale for this item");
    }
  }

  function onChangeScale(scale: RatingScale) {
    logger.debug("onChangeScale started: scale:", scale);

    const event = ModelService.updateItems<RatingMeasure>(state.model, [measure.key], RatingMeasureProps.ratingScale, scale);
    if (onEvent !== undefined) {
      onEvent(event);
    }

    logger.debug("onChangeScale finished:", event);
  }
}

export default RatingScaleCard;