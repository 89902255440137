import { Item } from "./Item";

/**
 * Holds a rating value for a specific Item.
 * A RatingValue refers to an Item, a RatingMeasure and a RatingPeriod
 * A Rating is like a Fact in a star schema - it can be aggregated across multiple dimensions.
 */
export interface RatingValue extends Item {
  /** The Item that this Rating is for */
  itemKey: string;

  /** The RatingMeasure that this Rating is using */
  measureKey: string;

  /** The RatingPeriod that this Rating is part of */
  periodKey: string;

  /** The Rating value - this is the "fact" that can be aggregated, sliced and diced */
  value: number;
}

/**
 * Groups a set of RatingValue items for a specific time period e.g. month, quarter, year, etc
 * Aggregations of Ratings occur within a RatingPeriod, or across multiple periods e.g. you could have a
 * capability rating for each quarter, but want to chart the rating as at the end of each year
 */
export interface RatingPeriod extends Item {
  periodEndDate: number;
}

export class RatingValueProps {
  // Keys from RatingValue
  static itemKey:keyof RatingValue = "itemKey";
  static measureKey:keyof RatingValue = "measureKey";
  static periodKey:keyof RatingValue = "periodKey";
  static value:keyof RatingValue = "value";

  // Keys of RatingPeriod
  static periodEndDate:keyof RatingPeriod = "periodEndDate";

  // Array of all keys not inherited from ItemProps
  static ALL:string[] = [
    RatingValueProps.itemKey,
    RatingValueProps.measureKey,
    RatingValueProps.periodKey,
    RatingValueProps.value,
    RatingValueProps.periodEndDate,
  ]
}
