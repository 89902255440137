import { Item, ItemProps } from "./Item";

/**
 * Defines a RatingMeasure type that can be used to rate Items. This is comprised of
 * a RatingScale (e.g. 1-5, XS-XL, etc) and a RatingCalc that calculates rolled
 * up ratings
 */
export interface RatingMeasure extends Item {
  ratingScale: RatingScale;
  ratingCalc?: RatingCalc;
  chartType: ChartType;
}

/**
 * Defines a scale that is used to rate an Item, usually a numeric 1-5 type scale
 * is used for most items (e.g. 1-5, XS-XL, Basic-Excelling, etc)
 */
export interface RatingScale {
  name: string;
  scale: RatingScaleItem[];
}

/**
 * Defines a element in RatingScale comprising a numeric value (the fact), a name,
 * and a color that is used when rendering the RatingScale
 */
export interface RatingScaleItem {
  value: number;
  name: string;
  description?: string;
  color?: any;
}

/**
 * Defines a rating calculation used to aggregrate ratings across multiple items,
 * for example take the average of all children, or the min/max value, or possibly
 * use a matrix (e.g. a risk matrix)
 */
export interface RatingCalc {
  key: string;
  name: string;
  description: string;
}

/** If a MATRIX calculation, this contains required details  */
export interface RatingCalcMatrix extends RatingCalc {
  /** The RatingMeasure that provides a RatingScale for the x-axis */
  xaxisKey: string;

  /** The RatingMeasure that provides a RatingScale for the y-axis */
  yaxisKey: string;

  /** The 2-dimensional array of values that are looked up to calculate the rating value */
  values: number[][];
}

/** Options for supported chart types */
export interface ChartType {
  key: string;
  name: string;
  description: string;
}

/**
 * Inherit all
 */
export class RatingMeasureProps extends ItemProps {
  // Keys of RatingMeasure
  static ratingScale:keyof RatingMeasure = "ratingScale";
  static ratingCalc:keyof RatingMeasure = "ratingCalc";
  static chartType:keyof RatingMeasure = "chartType";

  // Keys of RatingScale
  static scale:keyof RatingScale = "scale";

  // Keys of RatingScaleItem
  static value:keyof RatingScaleItem = "value";
  static color:keyof RatingScaleItem = "color";

  // Keys of RatingCalc - nothing unique

  // Keys of RatingCalcMatrix
  static xaxisKey:keyof RatingCalcMatrix = "xaxisKey";
  static yaxisKey:keyof RatingCalcMatrix = "yaxisKey";
  static values:keyof RatingCalcMatrix = "values";

  // Array of all keys not inherited from ItemProps
  static ALL:string[] = [
    RatingMeasureProps.ratingScale,
    RatingMeasureProps.ratingCalc,
    RatingMeasureProps.scale,
    RatingMeasureProps.color,
    RatingMeasureProps.value,
    RatingMeasureProps.values,
    RatingMeasureProps.xaxisKey,
    RatingMeasureProps.yaxisKey,
  ]
}
