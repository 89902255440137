import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import * as ModelService from "../../services/ModelService";
import { ChartType, RatingMeasure } from '../../types/RatingMeasure';
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';
import { TypeItem, TypeItemProps } from '../../types/TypeItem';
import { DocumentLink } from '../../views/ViewLinks';
import ContextMenuBase from '../control/ContextMenuBase';
import { ChartTypeDropdown } from './RatingChartCard';

const logger = new Logger("cards.TypeItemCard");

function TypeItemCard(props: any) {
  const model = useModelContext().model;
  const className = props.className;
  const onEvent = props.onEvent;
  const [item, setItem] = useState<TypeItem>(props.item);

  logger.debug("Rendering: key=%s", item.key, item);

  // Render a Document view
  if (props.docView) return (
    <section className={className}>
      <div className="d-flex">
        <div className="flex-fill">
          <h2>Rating Measures</h2>
        </div>
      </div>
      <div className="mt-1">
        <TypeItemEditor item={item} onEvent={onEvent} />
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className={"shadow text-level-2 " + className}>
      <CardHeader className="border-0 d-flex">
        <div className="flex-fill">
          <h2>Rating Measures</h2>
        </div>
      </CardHeader>
      <CardBody className="">
        <TypeItemEditor item={item} onEvent={onEvent} />
      </CardBody>
    </Card>
  );

  function TypeItemEditor(props: any) {
    const item: TypeItem = props.item;
    const measures = model.getItemArray<RatingMeasure>(item.measureKeys);

    if (!item.chartKeys) {
      item.chartKeys = measures.map(measure => measure.chartType?.key);
    }

    logger.debug("TypeItemEditor Rendering:", item.measureKeys, item.chartKeys, measures);

    return (
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th>Rating</th>
            <th>Calculation</th>
            <th>Scale</th>
            <th>Chart Type</th>
            <th className="text-right">
              <AddMeasureMenu />
            </th>
          </tr>
        </thead>
        <tbody>
          { measures.map((measure, index) =>
            <tr key={measure.key}>
              <td><DocumentLink item={measure}/></td>
              <td>{measure.ratingCalc?.name}</td>
              <td>{measure.ratingScale.name}</td>
              <td>
                <ChartTypeDropdown selectedKey={item.chartKeys[index]} 
                                   onChange={(chartType:ChartType) => onChangeChartType(index,chartType)} />
              </td>
              <td className="rowmenu">
                <RemoveButton index={index} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );

    function AddMeasureMenu(props:any) {
      const measures = model.sortByName(model.getMeasures().filter(measure => isSelectable(measure)));
      return (
        <ContextMenuBase caret="far fa-plus" logger={logger.name}>
          { measures.map(measure =>
            <button className="btn-image item" onClick={() => onAddMeasure(measure)} key={measure.key}>
              {measure.name}
            </button>
          )}
        </ContextMenuBase>
      )

      function isSelectable(measure:RatingMeasure): boolean {
        return RatingMeasureUtils.hasScale(measure) && 
              !RatingMeasureUtils.hasScale(model.getItem<RatingMeasure>(measure.parentKey));
      }
    }

    function RemoveButton(props: any) {
      const index = props.index;

      return (
        <button className="btn-image" onClick={() => onRemoveMeasure(index)}>
          <i className="far fa-trash-alt"></i>
        </button>
      );
    }
  }

  function updateMeasureKeys(measureKeys: string[]) {
    const event = ModelService.updateItems(model, [item.key], TypeItemProps.measureKeys, measureKeys);

    setItem(model.getItem<TypeItem>(item.key));

    if (onEvent !== undefined) {
      onEvent(event);
    }
  }

  function updateChartKeys(chartKeys: string[]) {
    const event = ModelService.updateItems(model, [item.key], TypeItemProps.chartKeys, chartKeys);

    setItem(model.getItem<TypeItem>(item.key));

    if (onEvent !== undefined) {
      onEvent(event);
    }
  }

  function onAddMeasure(measure: RatingMeasure) {
    const measureKeys = item.measureKeys || [];
    const chartKeys = item.chartKeys || [];

    measureKeys.push(measure.key);
    chartKeys.push(measure.chartType.key);

    updateMeasureKeys(measureKeys);
    updateChartKeys(chartKeys);
  }

  function onRemoveMeasure(index: number) {
    const measureKeys = Array.from(item.measureKeys);
    const chartKeys = Array.from(item.chartKeys);

    if (index !== -1) {
      measureKeys.splice(index, 1);
      chartKeys.splice(index, 1);

      updateMeasureKeys(measureKeys);
      updateChartKeys(chartKeys);
    }
  }

  function onChangeChartType(index:number, chartType:ChartType) {
    const chartKeys = Array.from(item.chartKeys);
    if (index >= 0 && index < chartKeys.length) {
      chartKeys[index] = chartType.key;
      updateChartKeys(chartKeys);
    }
  }
}

export default TypeItemCard;
