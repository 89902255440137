import { Logger } from "../common/Logger";
import { Model } from "./Model";
import { RatingCalc, RatingCalcMatrix, RatingMeasure, RatingScale, RatingScaleItem } from "./RatingMeasure";
import { RatingValue } from "./RatingValue";

const logger = new Logger("types.RatingMeasureHelper");

/**
 * Class of helpers for managing the complexity of the RatingMeasure model safely
 */
export class RatingMeasureHelper {
  public readonly model:Model;
  public readonly measure:RatingMeasure;

  constructor(model: Model, measure: RatingMeasure | string) {
    this.model = model;
    if (typeof measure === "string") {
      this.measure = this.model.getItem<RatingMeasure>(measure);
    } else {
      this.measure = measure;
    }

    if (this.measure === undefined) {
      logger.warn("constructor: Specified measure does not exist", measure);
      throw Error("Specified measure does not exist:" + measure);
    }
  }

  public hasScale(): boolean {
    return RatingMeasureUtils.hasScale(this.measure);
  }

  public hasScaleItems(): boolean {
    return RatingMeasureUtils.hasScaleItems(this.measure);
  }

  public hasCalc(): boolean {
    return RatingMeasureUtils.hasCalc(this.measure);
  }

  public getScale(): RatingScale {
    return this.measure.ratingScale;
  }

  public getCalc(): RatingCalc {
    const calc:any = this.measure.ratingCalc;
    return calc;
  }

  public getCalcKey(): string {
    const calc = this.getCalc();
    if (calc === undefined) {
      logger.warn("getCalcKey: RatingCalc is undefined for measure %s", this.measure.key);
      return "";
    }
    return calc.key;
  }

  public getMatrix(): RatingCalcMatrix {
    const calcMatrix:any = this.measure.ratingCalc;
    return calcMatrix;
  }

  private getMatrixValue(x:number, y:number): number {
    try {
      return this.getMatrix().values[y][x];
    } catch (e) {
      return 0;
    }
  }

  /**
   * Lookup the value from a matrix using the values on the x and y axis. For example, a Risk Matrix
   * uses Likelyhood and Impact on the x,y axis, and the rating values of these are used to lookup
   * the overall Risk Rating from the matrix.
   * 
   * @param axisValues Array of RatingValue's that contain a value for x and y axis
   */
  public lookupMatrixValue(axisValues : RatingValue[]) : number {
    try {
      // Get the measureKey's for the x,y axis e.g. Likelyhood and Impact
      const xaxisKey = this.getMatrix().xaxisKey;
      const yaxisKey = this.getMatrix().yaxisKey;

      // Get the values of the ratings for each axis e.g. Likelyhood = Rare, Impact = Minor
      const xvalue = axisValues.find(value => value.measureKey === xaxisKey)?.value;
      const yvalue = axisValues.find(value => value.measureKey === yaxisKey)?.value;

      // If either axis cannot be found then we cannot continue
      if (xvalue === undefined) {
        logger.error("lookupMatrixValue: Cannot find value for itemKey=%s, XAxis key=%s in:", this.measure.key, xaxisKey, axisValues);
        return 0;
      }
      if (yvalue === undefined) {
        logger.error("lookupMatrixValue: Cannot find value for itemKey=%s, YAxis key=%s in:", this.measure.key, yaxisKey, axisValues);
        return 0;
      }

      // Convert the axis rating values to array indexes in the matrix
      const x = RatingMeasureUtils.findScaleItemIndex(this.getMatrixXScaleItems(), xvalue);
      const y = RatingMeasureUtils.findScaleItemIndex(this.getMatrixYScaleItems(), yvalue);

      // Lookup the value from the matrix using the x,y indexes
      const value = this.getMatrixValue(x, y);
      return value;

    } catch (e) {
      logger.error("lookupMatrixValue: Error %s:", e, axisValues);
      throw e;
    }
  }

  public findScaleItem(value:number) : RatingScaleItem | undefined {
    return RatingMeasureUtils.findScaleItem(this.getScale().scale, value);
  }

  public findScaleItemIndex(value:number) : number {
    return RatingMeasureUtils.findScaleItemIndex(this.getScale().scale, value);
  }

  public getMatrixScaleItem(xitem:RatingScaleItem, yitem:RatingScaleItem): RatingScaleItem | undefined {
    const x = this.getMatrixXScaleItems().findIndex(si => si.value === xitem.value);
    const y = this.getMatrixYScaleItems().findIndex(si => si.value === yitem.value);

    const value = this.getMatrixValue(x, y);
    const scale = this.getScale();
    const sitem = scale.scale.find(s => s.value === value);

    return sitem;
  }

  public getMatrixAxisOptions(): RatingMeasure[] {
    return this.model.children<RatingMeasure>(this.measure.key);
  }

  public getMatrixXScale(): RatingScale | undefined {
    try {
      const measure = this.model.getItem<RatingMeasure>(this.getMatrix().xaxisKey);
      return measure?.ratingScale;
    } catch (e) {
      return undefined;
    }
  }

  public getMatrixYScale(): RatingScale | undefined {
    try {
      const measure = this.model.getItem<RatingMeasure>(this.getMatrix().yaxisKey);
      return measure.ratingScale;
    } catch (e) {
      return undefined;
    }
  }

  public getMatrixXScaleItems(): RatingScaleItem[] {
    const xscale = this.getMatrixXScale();
    return (xscale !== undefined ? xscale.scale : []);
  }

  public getMatrixYScaleItems(): RatingScaleItem[] {
    const yscale = this.getMatrixYScale();
    return (yscale !== undefined ? yscale.scale : []);
  }

  public sortAscending(scale:RatingScaleItem[]) : RatingScaleItem[] {
    return Array.from(scale).sort((s1,s2) => s1.value - s2.value);
  }

  public sortDescending(scale:RatingScaleItem[]) : RatingScaleItem[] {
    return Array.from(scale).sort((s1,s2) => s2.value - s1.value);
  }

  public newRatingScale(scale:RatingScale): RatingScale {
    let newScale:RatingScale = {...scale};
    if (newScale.scale !== undefined) {
      newScale.scale = Array.from(scale.scale);
    }
    return newScale;
  }

  public newRatingCalc(calc:RatingCalc): RatingCalc {
    let newCalc:any = {...calc};
    switch (calc.key) {
      case "MATRIX":
        this.initMatrix(newCalc);
        break;
    }
    return newCalc;
  }

  public newRatingCalcMatrix(calc:RatingCalcMatrix, xaxisKey:string, yaxisKey:string): RatingCalcMatrix {
    const xaxis = this.model.getItem<RatingMeasure>(xaxisKey);
    const yaxis = this.model.getItem<RatingMeasure>(yaxisKey);

    const newCalc:RatingCalcMatrix = {...calc,
      xaxisKey: xaxisKey,
      yaxisKey: yaxisKey,
      values: this.initMatrixValues(xaxis, yaxis)
    };

    return newCalc;
  }

  private initMatrix(calc:RatingCalcMatrix) {
    // Identify default items for x-axis and y-axis
    const axis = this.model.children<RatingMeasure>(this.measure.key);
    const xaxis = axis[0];
    const yaxis = axis[1];

    // Now set the properties
    calc.xaxisKey = xaxis.key;
    calc.yaxisKey = yaxis.key;
    calc.values = this.initMatrixValues(xaxis, yaxis);
  }

  private initMatrixValues(xaxis:RatingMeasure, yaxis:RatingMeasure): number[][] {
    const vscale = this.measure.ratingScale.scale;
    const xscale = xaxis.ratingScale.scale;
    const yscale = yaxis.ratingScale.scale;
    const values = yscale.map((ys,y) => xscale.map((xs,x) => this.calcValue(vscale, x, y)));

    return values;
  }

  /**
   * Simple algorithm to calculate an initial rating value for the matrix
   * @param vscale 
   * @param x 
   * @param y 
   */
  private calcValue(vscale:RatingScaleItem[], x:number, y:number): number {
    const length = vscale.length - 1;
    const xvalue = vscale[x <= length ? x : length].value;
    const yvalue = vscale[y <= length ? y : length].value;

    return (xvalue > yvalue ? xvalue : yvalue);
  }
}

/**
 * Selection of static utility functions
 */
export class RatingMeasureUtils {

  public static hasScale(measure?:RatingMeasure): boolean {
    return (measure !== undefined) && (measure.ratingScale !== undefined);
  }

  public static hasScaleItems(measure?:RatingMeasure): boolean {
    return (measure !== undefined && measure.ratingScale !== undefined) 
          ? measure.ratingScale.scale.length > 0 : false;
  }

  public static hasCalc(measure?:RatingMeasure): boolean {
    return (measure !== undefined) && (measure.ratingCalc !== undefined);
  }

  public static hasCalcMatrix(measure?:RatingMeasure): boolean {
    return (measure !== undefined) && 
           (measure.ratingCalc !== undefined) && 
           (measure.ratingCalc.key === "MATRIX");
  }

  public static findScaleItem(scaleItems:RatingScaleItem[], value:number): RatingScaleItem | undefined {
    return RatingMeasureUtils.findScaleItemX(scaleItems, value).item;
  }

  public static findScaleItemIndex(scaleItems:RatingScaleItem[], value:number): number {
    return RatingMeasureUtils.findScaleItemX(scaleItems, value).index;
  }

  /**
   * Search the scaleItems array for the item where:
   * - value >= item[i].value AND value < item[i+1].value
   * 
   * We return { index: i, item: item } of that item in scaleItems array. Index is guaranteed to be 
   * the index into the RatingCalcMatris.values[][] array.
   * 
   * @param scaleItems 
   * @param value 
   */
  public static findScaleItemX(scaleItems:RatingScaleItem[], value:number): { index:number, item?:RatingScaleItem } {
    if (value <= 0) {
      return { index: -1, item: undefined }
    }
  
    let i=0;
    for (i=0; i < scaleItems.length; i++) {
      const item = scaleItems[i];
      if (value < item.value) {
        break;
      }
    }
    const j = (i === 0) ? 0 : i-1;
    return { index: j, item: scaleItems[j] }
  }
}