import * as d3Force from 'd3-force';
import * as d3Scale from 'd3-scale-chromatic';
import React from "react";
import { useHistory } from 'react-router-dom';
import { FlexibleXYPlot, LabelSeries, MarkSeries } from 'react-vis';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import { useViewContext } from '../../context/ViewContext';
import { BezierLineSeries } from './BezierLineSeries';
import { NetworkDataProvider } from "./NetworkDataProvider";

const logger = new Logger("chart.NetworkGraph");

function NetworkGraph(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const history = useHistory();

  const rootKey = props.rootKey;
  const className = props.className || "";
  
  const data = props.data || new NetworkDataProvider(model, viewState).addChildren(rootKey);

  const colorRange = Array.from(d3Scale.schemePaired);
  const colorDomain = getColorDomain(data);
  const sizeRange = [5,10];

  setStrokeWidth(data);

  const { nodes, links } = generateSimulation(data, 90);
  const nodesWithLabels = getNodesWithLabels(nodes, data.maxLevel);

  logger.debug("Rendering: %d nodes, %d links, levels=%d:", nodes.length, links.length, data.maxLevel, viewState, nodes, links);
  // logger.debug("colorRange, colorDomain, sizeRange:", colorRange, colorDomain, sizeRange);

  return (
    <FlexibleXYPlot 
      className={"force-graph " + className}
      colorType="category"
      colorRange={colorRange}
      colorDomain={colorDomain}
    >
    { links.map((link:any, index:number) => 
      <BezierLineSeries className="line"
        data={[link.source, link.target]}
        color={link.source.color}
        opacity={0.5}
        animation={false}
        style={{strokeWidth:link.width}}
        key={index}
      />
    )}
    <MarkSeries className="marks"
      data={nodes}
      animation={true}
      sizeRange={sizeRange}
      onValueClick={(node) => toggleOpen(node.key)}
    />
    <LabelSeries className="labels"
      data={nodesWithLabels}
      animation={true} 
      labelAnchorX="middle"
      allowOffsetToBeReversed={true}
      onValueClick={(node) => history.push("/network/" + node.key)}
    />
   </FlexibleXYPlot>
  )

  function toggleOpen(key:string) {
    const itemState = viewState.toggleOpenTree(key);
    viewState.onEvent({name:"NetworkGraph.toggleOpen", key:key, itemState:itemState});
  }

  /**
   * Create the list of nodes to render.
   * @returns {Array} Array of nodes.
   * @private
   */
  function generateSimulation(data:any, maxSteps:number) {
    const start = Date.now();

    // copy the data 
    const nodes = data.nodes.map((node:any) => ({...node, label:node.item.name, yOffset:(-node.size/1.5) }));
    const links = data.links.map((link:any) => ({...link}));

    // build the simuatation
    const simulation:any = d3Force.forceSimulation(nodes)
      .force('charge',  d3Force.forceManyBody().strength(-50))
      .force('link',    d3Force.forceLink(links).id((node:any) => node.key)
                                                .distance(25).iterations(4))
      .force('center',  d3Force.forceCenter())
      .force('collide', d3Force.forceCollide().radius(20).iterations(4))
      // .force('radial',  d3Force.forceRadial(150))
      .stop();

    const upperBound = Math.ceil(
      Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay())
    );
    const numTicks = Math.min(maxSteps, upperBound);
    simulation.tick(numTicks);

    logger.debug("generateSimulation: nodes=%d, links=%d, numTicks=%d: finished in %d ms", 
                  nodes.length, links.length, numTicks, Date.now()-start);

    return { nodes, links };
  }

  /**
   * Set colors and the react-vis color domain based upon the type of item (Item.typeKey) for each node
   * @param data 
   */
  function getColorDomain(data:NetworkDataProvider) : number[] {
    const types:string[] = [];
    for (const node of data.nodes) {
      const typeKey = node.item.typeKey;
      let index = types.indexOf(typeKey);
      if (index === -1) {
        index = types.push(typeKey) - 1;
      }
      node.color = index;
    }

    return types.map((c,i) => i);
  }

  function setStrokeWidth(data:NetworkDataProvider) {
    for (const link of data.links) {
      const node = data.getNode(link.target);
      link.width = 1 + (node ? data.maxLevel - node.level : 1);
    }
  }

  function getNodesWithLabels(nodes:any[], maxLevels:number) {
    const nodesWithLabels = (nodes.length < 100) ? nodes : nodes.filter((node:any) => (node.level < maxLevels));
    return nodesWithLabels;
  }
 }

export default NetworkGraph;
