import React from 'react';
import { ResponsiveRadar } from '@nivo/radar'
import { NivoOptions } from "./Nivo";

function NivoRadar(props) {
  const data = props.data;
  const keys = props.keys;
  const indexBy = props.indexBy || "name";
  const getValueLabel = props.getValueLabel;

  return (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy={indexBy}
      theme={NivoOptions.theme}
      colors={NivoOptions.colors}
      maxValue="auto"
      margin={{ top: 40, right: 60, bottom: 30, left: 80 }}
      curve="cardinalClosed"
      borderWidth={2}
      borderColor={{ from: 'color' }}
      gridLevels={5}
      gridShape="circular"
      gridLabelOffset={16}
      enableDots={true}
      dotSize={8}
      dotColor={{ theme: 'background' }}
      dotBorderWidth={2}
      dotBorderColor={{ from: 'color' }}
      enableDotLabel={true}
      dotLabel=""
      dotLabelYOffset={-12}
      fillOpacity={0.25}
      blendMode="multiply"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          translateX: -70,
          translateY: -30,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: NivoOptions.theme.textColor,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
      tooltipFormat={value => getValueLabel(value)}
    />
  )
}

export default NivoRadar;