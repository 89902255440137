import React, { CSSProperties, useState } from 'react';
import { SketchPicker, SwatchesPicker } from 'react-color';
import { usePopper } from 'react-popper';
import Logger from "../../common/Logger";
import { Item } from '../../types/Item';

const logger = new Logger("control.ColorPicker");

function ColorPicker(props:any) {
  const onChange = props.onChange;
  const item:Item = props.item;
  const property:keyof Item = props.property;
  const swatches = props.swatches;

  const initialColor = getInitialColor(item[property]);
  const [color, setColor] = useState(initialColor);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);

  logger.trace("Rendering: initColor=%s, color=%s", initialColor, color)

  const stylesCover:CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }
  
  const stylesColor:CSSProperties = {
    width: '45px',
    height: '1rem',
    borderRadius: '2px',
    background: color,
  }
  
  // const stylesSwatch:CSSProperties = {
  //   padding: '0px',
  //   background: '#fff',
  //   borderRadius: '4px',
  //   boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  //   display: 'inline-block',
  //   cursor: 'pointer',
  // }

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'offset', options: { offset: [0, 5] } },
      { name: 'arrow',  options: { element: arrowElement, padding: 5 } }
    ],
  });

  return (
    <div>
      <div className="align-middle">
        <div style={stylesColor} onClick={toggleOpen} ref={setReferenceElement} />
      </div>
      { displayColorPicker &&
        <>
          <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover">
            { swatches 
              ? <SwatchesPicker color={color} onChange={handleChange} />
              : <SketchPicker color={color} onChange={handleChange} />
            }
            <div ref={setArrowElement} style={styles.arrow} />
          </div>
          <div style={stylesCover} onClick={handleClose} />
        </>
      }
    </div>
  );

  function getInitialColor(value:any): string {
    return (value !== undefined) ? value : "#9013FE";
  }
  
  function toggleOpen() {
    setDisplayColorPicker(!displayColorPicker);
  }

  function handleClose(e:any) {
    logger.debug("handleClose: color=", color);

    onChange(item, property, color);
    setDisplayColorPicker(false);
  }

  function handleChange(color:any, e:any) {
    logger.debug("handleChange: color=", color.hex);
    setColor(color.hex);
  }
}

export default ColorPicker;