import React from 'react';
import Logger from '../../common/Logger';
import { useModelContext } from '../../context/ModelContext';
import { PeriodSeriesProvider2 } from './DataProvider';
import NivoBullet from './NivoBullet';
import { Item } from '../../types/Item';
import { DocumentLink } from '../../views/ViewLinks';

const logger = new Logger("rating.RatingChartBullet");

/**
 * Display the RatingValue's for specified items using a radar chart
 */
export function RatingChartBullet(props:any) {
  const model = useModelContext().model;
  const { items, measure, classRow } = props;

  if (!items || items.length === 0) {
    return <></>;
  }

  logger.debug("Rendering:", items);
 
  return (
    <div className={classRow}>
      { items.map((item:Item) =>
        <div className="col" key={item.key}>
          <div className="chart-rating">
            <div className="text-center h4">
              <DocumentLink item={item} />
            </div>
            <Bullet item={item} />
          </div>
        </div>
      )}
    </div>
  );

  function Bullet(props:any) {
    const item = props.item;
    const series = new PeriodSeriesProvider2(model, measure, item);

    return <NivoBullet data={series.data} colors={series.colors} formatTooltip={getValueLabel} />

    function getValueLabel(value:number) : string {
      return series.getScaleItemName(value);
    }
  }
}

