import Logger from "../common/Logger";
import * as ModelService from "./ModelService";

const logger = new Logger("services.WebSocket");

const wsURL = process.env.REACT_APP_WS_URL || "";
const retryTimeout = 5000;

let socket:WebSocket;
let timeoutId:any;

export function openWebSocket() {
  logger.debug("onOpen: Opening connection to %s:", wsURL);

  // Create a socket connection
  timeoutId = null;
  socket = new WebSocket(wsURL);

  // Called to acknowledge that socket has been opened
  socket.onopen = function(event) {
    logger.debug("onOpen: Connection established to %s:", wsURL, event);
    sendMessage("My name is Peter");
  }

  socket.onmessage = function(event) {
    logger.debug("onMessage: Data received from server:", event);

    try {
      if (event.data !== undefined) {
        const data = JSON.parse(event.data);
        if (data.events !== undefined) {
          const events = data.events;
          logger.debug("onMessage: Received events:", events);
          ModelService.processEvents(events);
        }
      }
    } catch (e) {
      logger.error("onMessage: Error handling message:", event.data, e);
    }
  }

  socket.onclose = function(event) {
    let retry = retryTimeout;

    if (event.wasClean) {
      logger.debug("onClose: Connection closed cleanly, code=%s, reason=%s", event.code, event.reason);
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      logger.info("onClose: Connection died:", event);
      retry = 500;
    }

    // Set a timeout to retry
    reopenWebSocket(retry);
  };

  socket.onerror = function(error) {
    logger.error("onError:", error);
    reopenWebSocket(500);
  }
}

function reopenWebSocket(ms:number) {
  if (timeoutId !== null) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
  logger.info("reopenWebSocket: Retrying connection in %d ms:", ms);
  timeoutId = setTimeout(openWebSocket, ms);
}

export function sendMessage(data:any) : boolean {
  if (socket !== undefined && socket.readyState === socket.OPEN) {
    const message = { "action":"sendmessage", "data": data }
    logger.debug("sendMessage: sending", message);

    socket.send(JSON.stringify(message));
    return true;
  }

  logger.error("sendMessage: Unable to send, socket.readyState=%s", socket?.readyState);
  return false;
}

export function sendEvents(events:any) : boolean {
  if (socket !== undefined && socket.readyState === socket.OPEN) {
    const message = { "action":"sendmessage", "events": events }
    logger.debug("sendEvents: sending", message);

    socket.send(JSON.stringify(message));
    return true;
  }

  logger.error("sendEvents: Unable to send, socket.readyState=%s", socket?.readyState);
  return false;
}
