import { Item } from "../../types/Item";
import { Model } from "../../types/Model";
import { RatingMeasure, RatingScaleItem } from "../../types/RatingMeasure";
import { RatingMeasureHelper, RatingMeasureUtils } from "../../types/RatingMeasureHelper";
import { RatingModel } from "../../types/RatingModel";
import { RatingPeriod } from "../../types/RatingValue";
import Logger from "../../common/Logger";

const logger = new Logger("rating.DataProvider");

export class DataProvider {
  protected model: Model;
  protected measure: RatingMeasure;
  protected helper: RatingMeasureHelper;
  protected ratingModel: RatingModel;
  protected ratingPeriods: RatingPeriod[];
  protected currentPeriod: RatingPeriod;

  public minValue: number = Infinity;
  public maxValue: number = -Infinity;

  constructor(model:Model, measure: RatingMeasure | string) {
    this.helper = new RatingMeasureHelper(model, measure);
    this.model = model;
    this.measure = this.helper.measure;
    this.ratingModel = model.getRatingModel();
    this.ratingPeriods = model.getRatingPeriods();
    this.currentPeriod = model.getRatingPeriodForDate(Date.now());
  }

  protected setMinMax(value:number) {
    this.minValue = Math.min(this.minValue, value);
    this.maxValue = Math.max(this.maxValue, value);
  }

  public hasScaleItems() : boolean {
    return this.helper.hasScaleItems();
  }

  public getScaleItems() : RatingScaleItem[] {
    return this.helper.getScale().scale;
  }

  public getScaleItemName(value:number) {
    const si = RatingMeasureUtils.findScaleItem(this.getScaleItems(), value);
    return (si === undefined) ? value.toString() : si.name;
  }

  public getScaleMin() : number {
    let min = Infinity;
    for (const si of this.getScaleItems()) {
      if (si.value < min) {
        min = si.value;
      }
    }
    return min;
  }

  public getScaleMax() : number {
    let max = -Infinity;
    for (const si of this.getScaleItems()) {
      if (si.value > max) {
        max = si.value;
      }
    }
    return max;
  }

  public getScaleFactor() : number {
    const calcKey = this.helper.getCalcKey();
    
    let maxScore = this.getScaleMax();
    if (calcKey === "TOTAL" || calcKey === "PERCENT") {
      maxScore = this.maxValue;
    }

    const factor = maxScore / this.getScaleMax();

    logger.debug("getScaleFactor: measureKey=%s, factor=%f, maxScale=%f, maxScore=%f",
                  this.measure.key, factor, this.getScaleMax(), maxScore, this)
    return factor;
  }

  public getScaleValues() : number[] {
    // If we have a scale, then just apply the scale factor
    const scaleItems = this.getScaleItems();
    if (scaleItems.length > 0) {
      const factor = this.getScaleFactor();
      return scaleItems.map(si => si.value * factor);
    }

    // Otherwise we need derive 5-6 increments rounded to the nearest 0.5
    const range = this.maxValue - this.minValue;
    const exp = Math.trunc(Math.log10(range));
    const divisor = Math.pow(10, exp);
    const start  = Math.min(0, Math.round(this.minValue / divisor) * divisor);
    const finish = Math.round(this.maxValue / divisor) * divisor;
    const step = (finish - start) / 5;

    const values:number[] = [];
    for (let value = start; value <= finish;  value += step) {
      values.push(value);
    }

    logger.debug("getScaleValues: start=%f, finish=%f, step=%f, values:",
                  start, finish, step, values);

    return values;
  }
 }

export class PeriodDataProvider extends DataProvider {
  public names:  string[] = [];
  public values: number[] = [];

  constructor(model:Model, measure: RatingMeasure | string, item:Item) {
    super(model, measure);

    for (const ratingPeriod of this.ratingPeriods) {
      const ratingValue = this.ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
      const value = ratingValue.value;

      this.names.push(ratingPeriod.name);
      this.values.push(value);
      this.setMinMax(value);
    }
  }
}

export class ScaleDataProvider extends DataProvider {
  public names:string[] = [];
  public colors:string[] = [];
  public values:number[] = [];
  public percents:number[] = [];
  public maxScale = this.getScaleMax();
  public currentValue: number;

  constructor(model:Model, measure: RatingMeasure | string, item:Item, ratingPeriod?:RatingPeriod) {
    super(model, measure);

    this.minValue = this.getScaleMin();
    this.maxValue = this.maxScale;

    const scale = this.getScaleItems();
    const calcKey = this.helper.getCalcKey();
    
    if (calcKey === "TOTAL" || calcKey === "PERCENT") {
      this.maxValue = this.ratingModel.getMaxScore(item.key, this.measure.key);
    }
  
    if ((!this.maxValue || this.maxValue <= 0) && this.maxScale > 0) {
      this.maxValue = this.maxScale;
    }

    if (!ratingPeriod) {
      ratingPeriod = this.currentPeriod;
    }
    const ratingValue = this.ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
    this.currentValue = ratingValue.value;

    let prev=0;
    scale.forEach((scaleItem, i) => {
      this.names.push(scaleItem.name);
      this.colors.push(scaleItem.color);
      this.values.push(scaleItem.value);
      this.percents.push((scaleItem.value - prev) / this.maxValue);
      prev = scaleItem.value;
    });
  }
}

/**
 * Return an array with 1 element per RatingPeriod. Each element has a name (period.name)
 * and a property named item.name, with a value from RatingModel.getValue.
 * 
 * [
 *   { "name": FY21,
 *     "item[0].name": rating value for item[0] in FY21
 *     "item[1].name": rating value for item[1] in FY21
 *     "item[2].name": rating value for item[2] in FY21
 *   },
 * 
 *   { "name": FY22,
 *     "item[0].name": rating value for item[0] in FY22
 *     "item[1].name": rating value for item[1] in FY22
 *     "item[2].name": rating value for item[2] in FY22
 *   }
 * 
 *   { "name": FY23,
 *     "item[0].name": rating value for item[0] in FY23
 *     "item[1].name": rating value for item[1] in FY23
 *     "item[2].name": rating value for item[2] in FY23
 *   },
 * ]
 */
export class PeriodSeriesProvider extends DataProvider {
  public data: any[] = [];
  public keys: string[];

  constructor(model:Model, measure: RatingMeasure | string, items:Item[]) {
    super(model, measure);
    this.keys = items.map(item => item.name);
  
    const ratingModel = this.model.getRatingModel();
    const ratingPeriods = this.model.getRatingPeriods();

    for (const ratingPeriod of ratingPeriods) {
      const dataItem:any = { period: ratingPeriod.name }

      for (const item of items) {
        const ratingValue = ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
        dataItem[item.name] = ratingValue.value;

        this.setMinMax(ratingValue.value);
      }

      this.data.push(dataItem);
    }
  }
}

/**
 * Return an array with 1 element per RatingPeriod. Each element has an id (period.name)
 * and a properties as follows:
 * - ranges: this is the scale values
 * - measures: rating value for the period (single item in array)
 * - markers: rating value for the period (single item in array)
 * 
 * [
 *   { "id": "FY21",
 *     "ranges": [0,6,8,10],
 *     "measures": rating value for FY21
 *     "markers": rating value for FY21
 *   },
 * 
 *   { "id": FY22,
 *     "ranges": [0,6,8,10],
 *     "measures": rating value for FY22
 *     "markers": rating value for FY22
 *   }
 * 
 *   { "id": FY23,
 *     "ranges": [0,6,8,10],
 *     "measures": rating value for FY23
 *     "markers": rating value for FY23
 *   },
 * ]
 */
export class PeriodSeriesProvider2 extends DataProvider {
  public data: any[] = [];
  public colors: string[];

  constructor(model:Model, measure: RatingMeasure | string, item:Item) {
    super(model, measure);
  
    const ratingModel = this.model.getRatingModel();
    const ratingPeriods = this.model.getRatingPeriods();
    const scale = this.getScaleValues();

    this.colors = this.getScaleItems().map(si => si.color);

    for (const ratingPeriod of ratingPeriods) {
      const ratingValue = ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
      this.setMinMax(ratingValue.value);

      const dataItem:any = { 
        id: ratingPeriod.name,
        ranges: scale,
        measures: [ratingValue.value],
        markers: [ratingValue.value]
      }
      this.data.push(dataItem);
    }
  }
}

/**
 * Return an array with 1 element per item. Each element has a name (item.name) and a
 * property named after each RatingPeriod, with a value from RatingModel.getValue.
 * 
 * [
 *   { "name": item[0].name,
 *     "FY21": rating value for item[0] in FY21
 *     "FY22": rating value for item[0] in FY22
 *     "FY23": rating value for item[0] in FY23
 *   },
 * 
 *   { "name": item[1].name,
 *     "FY21": rating value for item[1] in FY21
 *     "FY22": rating value for item[1] in FY22
 *     "FY23": rating value for item[1] in FY23
 *   }
 * ]
 */
export class ItemSeriesProvider extends DataProvider {
  public data: any[] = [];
  public keys: string[];

  constructor(model:Model, measure: RatingMeasure | string, items:Item[]) {
    super(model, measure);
  
    const ratingModel = this.model.getRatingModel();
    const ratingPeriods = this.model.getRatingPeriods();

    this.keys = ratingPeriods.map(period => period.name);

    for (const item of items) {
      const dataItem:any = { name: item.name }

      for (const ratingPeriod of ratingPeriods) {
        const ratingValue = ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
        dataItem[ratingPeriod.name] = ratingValue.value;

        this.setMinMax(ratingValue.value);
      }

      this.data.push(dataItem);
    }
  }
}

/**
 * Return an array with 1 element per item. Each element has a name (item.name) and a
 * property named after each RatingPeriod, with a value from RatingModel.getValue.
 * 
 * [
 *   { "id": item[0].name,
 *     "value": rating value for item[0] in ratingPeriod
 *   },
 *   { "id": item[1].name,
 *     "value": rating value for item[1] in ratingPeriod
 *   },
 *   { "id": item[2].name,
 *     "value": rating value for item[2] in ratingPeriod
 *   }
 * ]
 */
export class ItemSeriesProvider2 extends DataProvider {
  public data: any[] = [];

  constructor(model:Model, measure: RatingMeasure | string, items:Item[], ratingPeriod:RatingPeriod) {
    super(model, measure);
  
    const ratingModel = this.model.getRatingModel();

    for (const item of items) {
      const ratingValue = ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);
      this.setMinMax(ratingValue.value);

      const dataItem:any = { 
        id: item.name, 
        value: Math.abs(ratingValue.value),
        rawValue: ratingValue.value
      }
      this.data.push(dataItem);
    }
  }
}

/**
 * Return an array with 1 element per item. Each element has a name (item.name) and a
 * property named after each RatingPeriod, with a value from RatingModel.getValue.
 * 
 * [
 *   { 
 *     "id": item[0].name,
 *     "data" : [
 *       { 
 *         "x": 2021,
 *         "y": rating value for item[0] in FY21
 *       }
 *       { 
 *         "x": 2022,
 *         "y": rating value for item[0] in FY22
 *       }
 *       { 
 *         "x": 2023,
 *         "y": rating value for item[0] in FY23
 *       }
 *     ]
 *   },
 *   { 
 *     "id": item[1].name,
 *     "data" : [
 *       { 
 *         "x": 2021,
 *         "y": rating value for item[1] in FY21
 *       }
 *       { 
 *         "x": 2022,
 *         "y": rating value for item[2] in FY22
 *       }
 *       { 
 *         "x": 2023,
 *         "y": rating value for item[3] in FY23
 *       }
 *     ]
 *   },
 * ]
 */
export class ItemSeriesProvider3 extends DataProvider {
  public data: any[] = [];

  constructor(model:Model, measure: RatingMeasure | string, items:Item[]) {
    super(model, measure);
  
    const ratingModel = this.model.getRatingModel();
    const ratingPeriods = this.model.getRatingPeriods();

    for (const item of items) {
      const dataItem:any = { id:item.name, data:[] }

      for (const ratingPeriod of ratingPeriods) {
        const ratingValue = ratingModel.getValue(item.key, this.measure.key, ratingPeriod.key, true);

        dataItem.data.push({
          x: new Date(ratingPeriod.periodEndDate).getFullYear(),
          y: ratingValue.value
        });

        this.setMinMax(ratingValue.value);
      }

      this.data.push(dataItem);
    }
  }
}
