import React from 'react';
import Logger from "../../common/Logger";
import { useModelContext } from '../../context/ModelContext';
import { CanvasItem } from './ModelCanvas';

const logger = new Logger("canvas.BusinessModelCanvas");

function BusinessModelCanvas(props:any) {
  const model = useModelContext().model;
  const { rootKey, showDescription } = props;

  const itemProps:any = {
    KA: { typeCode: "ZZ-1213", classHeader: "cost" },      // Key Attributes
    KP: { typeCode: "ZZ-1215", classHeader: "cost" },      // Key Partners
    KR: { typeCode: "ZZ-1214", classHeader: "cost" },      // Key Resources
    VP: { typeCode: "ZZ-1218", classHeader: "value" },     // Value Propositions
    CR: { typeCode: "ZZ-1211", classHeader: "revenue" },   // Customer Relationships
    CS: { typeCode: "ZZ-1210", classHeader: "revenue" },   // Customer Segments
    CH: { typeCode: "ZZ-1212", classHeader: "revenue" },   // Channels
    C$: { typeCode: "ZZ-1216", classHeader: "cost" },      // Cost Structure
    R$: { typeCode: "ZZ-1217", classHeader: "revenue" },   // Revenue Streams
  }

  const children = model.children(rootKey);

  logger.debug("Rendering: rootKey=%s, showDescription=%s", rootKey, showDescription, itemProps );

  return (
    <div className="canvas">
      <div className="row">
        <CanvasItemCol itemProps={itemProps.KA}/>
        <div className="col-12 col-sm-6 col-lg col">
          <div className="row">
            <CanvasItemCol itemProps={itemProps.KP} className="col-12 col"/>
          </div>
          <div className="row">
            <CanvasItemCol itemProps={itemProps.KR} className="col-12 col"/>
          </div>
        </div>
        <CanvasItemCol itemProps={itemProps.VP} className="col-12 col-lg col"/>
        <div className="col-12 col-sm-6 col-lg col">
          <div className="row">
            <CanvasItemCol itemProps={itemProps.CR} className="col-12 col"/>
          </div>
          <div className="row">
            <CanvasItemCol itemProps={itemProps.CH} className="col-12 col"/>
          </div>
        </div>
        <CanvasItemCol itemProps={itemProps.CS}/>
      </div>
      <div className="row">
        <CanvasItemCol itemProps={itemProps.C$}/>
        <CanvasItemCol itemProps={itemProps.R$}/>
      </div>
    </div>
  );

  function CanvasItemCol(props:any) {
    const itemProps = props.itemProps;
    const className = props.className || "col-12 col-sm-6 col-lg col";
    const typeItem = model.getItemByCode(itemProps.typeCode);

    const item = children.find(child => (child.typeKey === typeItem?.key));
    
    if (item) return (
      <div className={className}>
        <CanvasItem itemKey={item.key} className={"item " + itemProps.classHeader} showDescription={showDescription}/>
      </div>
    )

    return <></>
  }
}

export default BusinessModelCanvas;
