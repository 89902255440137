import React from 'react';
import { ResponsiveBullet } from '@nivo/bullet'
import { NivoOptions } from "./Nivo";

function NivoBullet(props) {
  const { data, colors, formatTooltip } = props;

  return (
    <ResponsiveBullet
      data={data}
      margin={{ top: 10, right: 20, bottom: 50, left: 50 }}
      spacing={60}
      titleAlign="start"
      titleOffsetX={-50}
      measureSize={0.05}
      rangeColors={colors}
      rangeComponent={CustomRange}
      markerComponent={CustomMarker}
      tooltipFormat={value => formatTooltip(value)}
      animate={true}
      motionStiffness={90}
      motionDamping={12}
      theme={NivoOptions.theme}
      colors={NivoOptions.colors}
    />
  )

  function CustomRange({ x, y, width, height, color, onMouseEnter, onMouseMove, onMouseLeave }) {
    return (
      <rect
        x={x}
        y={y+3}
        rx={3}
        ry={3}
        width={width}
        height={height-6}
        fill={color}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    )
  }

  function CustomMarker({ x, size, color, onMouseEnter, onMouseMove, onMouseLeave }) {
    return (
      <g
        transform={`translate(${x},0)`}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        <path transform={`translate(0,${size-4})`} d="M0 -8 L 8 0 L 0 8 L -8 0 Z" fill={color} />
        {/* <line
          x1={0}
          x2={0}
          y1={0}
          y2={size}
          stroke={color}
          strokeWidth={2}
          strokeDasharray="2,3"
          fill="none"
        />
        <path d="M0 -10 L 10 0 L 0 10 L -10 0 Z" fill={color} />
        <path transform={`translate(0,${size})`} d="M0 -10 L 10 0 L 0 10 L -10 0 Z" fill={color} /> */}
      </g>
    )
  }
}

export default NivoBullet;