import Logger from "../common/Logger";
import { Item } from "../types/Item";
import { Model } from "../types/Model";

const logger = new Logger("context.ViewState");

export interface ItemState {
  key: string;
  level: number;
  openTree: boolean;
  openItem: boolean;
  selected: boolean;
  searchHit: boolean;
  active: boolean;
  visible: boolean;
  visibleChildrenCount: number;
}

/**
 * ViewState holds view related information about Item's (as defined by ItemState) that are in the current view
 */
export class ViewState {
  /** The item at the root, or centre, of this view */
  private rootKey:string = "";

  /** Map of ItemState elements, keyed by the model Item.key */
  private itemStateMap = new Map<string,ItemState>();

  /** Array of items put on the "clipboard" */
  private clipboardItems:Item[] = [];

  /** */
  private onEventHandler:any;

  /** The level to which the "item tree" is open */
  public openLevel:number = 1;

  /** Is the item selection checkbox visible or not */
  public showSelectCheckbox:boolean = false;

  /** */
  public showCountBar = false;

  /** Rating display option: 0=Dropdown, 1=Values, 2=Slider */
  public ratingDisplay = 0;

  constructor(old?:ViewState, props?:any) {
    if (old !== undefined) {
      this.rootKey = old.rootKey;
      this.itemStateMap = new Map(old.itemStateMap);
      this.clipboardItems = Array.from(old.clipboardItems);
      this.onEventHandler = old.onEventHandler;
      this.openLevel = old.openLevel;
      this.showCountBar = old.showCountBar;
      this.showSelectCheckbox = old.showSelectCheckbox;
    }
    if (props) {
      this.onEventHandler = props.onEvent;
      this.rootKey = props.rootKey || "";
    }
  }

  public onEvent(event:any) {
    if (this.onEventHandler !== undefined) {
      logger.debug("onEvent: Calling onEventHandler:", event, this);
      this.onEventHandler(event);
    } else {
      logger.debug("onEvent:", event, this);
    }
  }

  public has(key:string) {
    return this.itemStateMap.has(key);
  }

  public get(key:string): ItemState {
    let item = this.itemStateMap.get(key);
    if (item === undefined) {
      item = this.getNew(key);
    }
    return item;
  }

  private getNew(key:string): ItemState {
    let newItem:ItemState;
    let oldItem = this.itemStateMap.get(key);
    if (oldItem !== undefined) {
      newItem = { ...oldItem }
    } else {
      newItem = {
        key: key,
        level: 0,
        openTree: false,
        openItem: false,
        selected: false,
        searchHit: false,
        active: false,
        visible: true,
        visibleChildrenCount: 0,
      }
    }
    
    this.itemStateMap.set(key, newItem);
    return newItem;
  }

  public getSelected(): ItemState[] {
    let keys:ItemState[] = [];

    this.itemStateMap.forEach(item => {
      if (item.selected) {
        keys.push(item);
      }
    });

    return keys;
  }

  public getSelectedKeys(): string[] {
    let keys:string[] = [];

    this.itemStateMap.forEach(item => {
      if (item.selected) {
        keys.push(item.key);
      }
    });

    return keys;
  }

  public hasSelection(): boolean {
    return this.getSelectedKeys().length > 0;
  }

  public clearSelected() {
    this.itemStateMap.forEach(item => {
      this.getNew(item.key).selected = false;
    });
  }

  public clearActive() {
    this.itemStateMap.forEach(item => {
      this.getNew(item.key).active = false;
    });
  }
  
  public isOpenTree(key:string): boolean {
    return this.get(key).openTree;
  }

  public isOpenItem(key:string): boolean {
    return this.get(key).openItem;
  }

  public isActive(key:string): boolean {
    return this.get(key).active;
  }

  public isSelected(key:string): boolean {
    return this.get(key).selected;
  }

  public isSearchHit(key:string): boolean {
    return this.get(key).searchHit;
  }

  public isVisible(key:string): boolean {
    return this.get(key).visible;
  }

  public toggleOpenTree(key:string): ItemState {
    const itemState = this.getNew(key);
    itemState.openTree = !itemState.openTree;

    logger.debug("toggleOpenTree: key=%s, itemState:", key, itemState);
    return itemState;
  }

  public toggleOpenItem(key:string): ItemState {
    const itemState = this.getNew(key);
    itemState.openItem = !itemState.openItem;
    return itemState;
  }

  public toggleSelected(key:string): ItemState {
    const itemState = this.getNew(key);
    itemState.selected = !itemState.selected;
    return itemState;
  }

  public setSelected(model:Model, key:string, selected:boolean, recursive:boolean): ItemState {
    const itemState = this.getNew(key);
    itemState.selected = selected;

    if (recursive) {
      const ckeys = model.childrenKeysDeep(key);
      logger.debug("setSelected: key=%s, ckeys:", key, ckeys);

      for (const ckey of ckeys) {
        this.getNew(ckey).selected = selected;
      }
    }

    return itemState;
  }

  public setOpenLevel(model:Model, key:string, maxLevel:number, reset:boolean=true) {
    if (key === undefined) {
      key = this.rootKey;
    }
    if (reset) {
      this.itemStateMap.clear();
      this.openLevel = 0;
    }

    logger.debug("setOpenLevel: key=%s, maxLevel=%d, reset=%s", key, maxLevel, reset);

    // if (maxLevel > this.openLevel) {
    //   this.openTree(model, key, maxLevel);
    // } else {
    //   this.itemStateMap.forEach(itemState => {
    //     if (itemState.level > maxLevel) {
    //       itemState.openTree = false;
    //     }
    //   });  
    // }
    this.openTree(model, key, maxLevel);
    this.openLevel = maxLevel;
  }

  private openTree(model:Model, key:string, maxLevel:number, level:number=1) : number {
    const itemState = this.get(key);
    itemState.level = level;
    itemState.openTree = true;
    itemState.visibleChildrenCount = 0;

    const relatedKeys = model.relatedKeys(key);

    logger.trace("openTree: key=%s, maxLevel=%d, level=%d, relatedKeys:", key, maxLevel, level, relatedKeys);

    for (const childKey of relatedKeys) {
      const childState = this.get(childKey);
      childState.visible = this.isViewable(model,childKey);
      if (childState.visible) {
        itemState.visibleChildrenCount++;
      }

      // Recurse through children
      if (level < maxLevel) {
        itemState.visibleChildrenCount += this.openTree(model, childKey, maxLevel, level+1);
      }
    }

    return itemState.visibleChildrenCount;
  }

  public openTo(model:Model, key:string) {
    this.clearActive();
    this.get(key).active = true;

    for (let i=0; key && key !== ""; i++) {
      const itemState = this.getNew(key);
      itemState.visible = true;
      if (i > 0) {
        itemState.openTree = true;
      }
      key = model.getItem(key).parentKey;
    }
  }

  public getVisibleChildrenCount(key:string) : number {
    return this.get(key).visibleChildrenCount;
  }

  public hasViewableChildren(model:Model, itemKey:string) : boolean {
    const childKeys = model.childrenKeys(itemKey);
    const index = childKeys.findIndex(key => this.isViewable(model,key));

    logger.trace("hasViewableChildren: key=%s, index=%d, childKey='%s'", itemKey, index, index !== -1 ? childKeys[index] : "")
    return (index !== -1);
  }

  public isViewable(model:Model, key:string) : boolean {
    return !key.startsWith(Model.KeyPrefixRatingValue);
  }

  /**
   * Return array of all viewable items (i.e. all except RatingValues)
   */
  public getViewableItems(model:Model) : Item[] {
    const items = model.getItems().filter(item => this.isViewable(model,item.key));
    return model.sortByName(items);
  }

  /**
   * Recursively set the search hit flag on all items that are in the searchResultsMap
   * @param model 
   * @param searchResults 
   */
  public setSearchHits(model:Model, searchResults:Item[]) {
    const start = Date.now();
    logger.trace("setSearchHits started:", searchResults);

    // Clear searchHit and visible for all items
    let reset = 0;
    model.getItems().forEach(item => {
      if (!model.isRatingValueKey(item.key)) {
        const itemState = this.getNew(item.key);
        itemState.searchHit = false;
        itemState.visible = false;
        reset++;
      }
    });

    logger.trace("setSearchHits: reset %d itemStates:", reset, this.itemStateMap);

    // Set searchHit and visible for each item in searchResults
    let hits=0, visible=0;
    searchResults.forEach(item => {
      const itemState = this.get(item.key);
      itemState.searchHit = true;
      itemState.visible = true;
      hits++;
      visible++;

      // Set visible for all parents up the tree
      while (item.parentKey !== "") {
        const parent = model.getItem(item.parentKey);
        if (parent === undefined) {
          logger.warn("setSearchHits: Item '%s' does not exist, but is referenced by %s.parentKey", item.parentKey, item.key);
          break;
        }

        const parentState = this.get(parent.key);
        parentState.visible = true;
        parentState.openTree = true;
        visible++;

        // Move to the next level
        item = parent;
      }
    });

    const duration = Date.now() - start;
    logger.debug("setSearchHits finished in %d ms: %d hits, %d visible", duration, hits, visible);
  }

  public clearSearchHits() {
    this.itemStateMap.forEach(item => {
      item.searchHit = false;
      item.visible = true;
    });
  }

  public getClipboard(): Item[] {
    return this.clipboardItems;
  }

  public setClipboard(items:Item[]) {
    this.clipboardItems = items;
  }

  public hasClipboardItems(): boolean {
    return this.clipboardItems.length !== 0;
  }
}
