import React from 'react';
import Logger from "../../common/Logger";
import { RatingScale } from "../../types/RatingMeasure";
import DropdownBase from '../control/DropdownBase';

import ratingScales from "./rating-scales.json";

const logger = new Logger("rating.RatingScaleDropdown");

function RatingScaleDropdown(props:any) {
  const { selected, onSelect } = props;

  const scales = Array.from(ratingScales).sort((s1,s2) => s1.name.localeCompare(s2.name));

  logger.trace("Rendering:");

  return (
    <DropdownBase selected={selected} logger={logger.name}>
      { scales.map(scale =>
        <button className="btn-image item" onClick={(e) => onClick(e, scale)} key={scale.name}>
          {scale.name}
        </button>
      )}
     </DropdownBase>
  )

  function onClick(e:any, scale:RatingScale) {
    if (onSelect !== undefined) {
      onSelect(scale);
    }
  }
}

export default RatingScaleDropdown;