import React from 'react';
import { ResponsiveBump } from '@nivo/bump'
import { NivoOptions } from "./Nivo";

function NivoBump(props) {
  const { data } = props;

  return (
    <ResponsiveBump
      data={data}
      margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
      theme={NivoOptions.theme}
      colors={NivoOptions.colors}
      lineWidth={3}
      activeLineWidth={8}
      inactiveLineWidth={3}
      inactiveOpacity={0.15}
      pointSize={10}
      activePointSize={16}
      inactivePointSize={0}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={3}
      activePointBorderWidth={3}
      pointBorderColor={{ from: 'serie.color' }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -36
      }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'ranking',
        legendPosition: 'middle',
        legendOffset: -40
      }}
    />
    // <ResponsiveBump
    //   data={data}
    //   keys={keys}
    //   indexBy={indexBy}
    //   theme={NivoOptions.theme}
    //   colors={NivoOptions.colors}
    //   maxValue="auto"
    //   margin={{ top: 40, right: 60, bottom: 30, left: 80 }}
    //   curve="cardinalClosed"
    //   borderWidth={2}
    //   borderColor={{ from: 'color' }}
    //   gridLevels={5}
    //   gridShape="circular"
    //   gridLabelOffset={16}
    //   enableDots={true}
    //   dotSize={8}
    //   dotColor={{ theme: 'background' }}
    //   dotBorderWidth={2}
    //   dotBorderColor={{ from: 'color' }}
    //   enableDotLabel={true}
    //   dotLabel=""
    //   dotLabelYOffset={-12}
    //   fillOpacity={0.25}
    //   blendMode="multiply"
    //   animate={true}
    //   motionStiffness={90}
    //   motionDamping={15}
    //   isInteractive={true}
    //   legends={[
    //     {
    //       anchor: 'top-left',
    //       direction: 'column',
    //       translateX: -70,
    //       translateY: -30,
    //       itemWidth: 80,
    //       itemHeight: 20,
    //       itemTextColor: NivoOptions.theme.textColor,
    //       symbolSize: 12,
    //       symbolShape: 'circle',
    //       effects: [
    //         {
    //           on: 'hover',
    //           style: {
    //             itemTextColor: '#000'
    //           }
    //         }
    //       ]
    //     }
    //   ]}
    //   tooltipFormat={value => getValueLabel(value)}
    // />
  )
}

export default NivoBump;