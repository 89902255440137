import React, { useState } from 'react';
import Logger from "../../common/Logger";
import { RatingPeriod, RatingValueProps } from "../../types/RatingValue";
import * as ModelService from "../../services/ModelService";
import { useModelContext } from '../../context/ModelContext';
import DatePicker from "react-datepicker";
import { endOfDay, lastDayOfMonth } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

const logger = new Logger("rating.RatingPeriodEndDate");

function RatingPeriodEndDate(props:any) {
  const state = useModelContext();
  const item:RatingPeriod = props.item;
  const className = props.className || "";
  const dateFormat = props.dateFormat || "'Period ending 'dd MMMM yyyy";
  const onEvent = props.onEvent;
  
  let initialDate = new Date();
  if (item.periodEndDate !== undefined) {
    initialDate = new Date(item.periodEndDate);
  }

  const [date, setDate] = useState(initialDate);

  logger.debug("Rendering:", item);

  return (
    <DatePicker selected={date} 
                dateFormat={dateFormat}
                onChange={onChange}
                customInput={ <DateButton/> }
                showMonthYearPicker
                popperPlacement="bottom"
                popperModifiers={{
                  offset: {
                    enabled: false,
                    offset: "5px, 10px"
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport"
                  }
                }}          
      />
  )

  function onChange(newDate:Date) {
    const endDate = endOfDay(lastDayOfMonth(newDate));
    setDate(endDate);

    const event = ModelService.updateItems<RatingPeriod>(state.model, [item.key],
                  RatingValueProps.periodEndDate, endDate.getTime());
    if (onEvent !== undefined) {
      onEvent(event);
    }
    return event;
  }

  function DateButton(props:any) {
    const value = props.value;
    const onClick = props.onClick;
    return (
      <button className={"btn-image " + className} onClick={onClick}>
        {value} <i className="fas fa-caret-down"></i>
      </button>
    );
  }
}

export default RatingPeriodEndDate;