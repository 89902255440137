import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Logger from "../../common/Logger";
import ItemDetailsEditor from '../model/ItemDetailsEditor';

const logger = new Logger("cards.ItemPropertiesCard");

function ItemPropertiesCard(props: any) {
  const item = props.item;
  const onEvent = props.onEvent;

  logger.debug("Rendering: key=%s", item.key, item);

  // Render a Document view
  if (props.docView) return (
    <section>
      <h2>Extended Properties</h2>
      <div className="mt-1">
        <ItemDetailsEditor item={item} onEvent={onEvent} />
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className="shadow text-level-2">
      <CardHeader>
        <div className="flex-fill">
          <h2>Extended Properties</h2>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <ItemDetailsEditor item={item} onEvent={onEvent} />
      </CardBody>
    </Card>
  );
}

export default ItemPropertiesCard;
