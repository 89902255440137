import React from 'react';
import Logger from '../../common/Logger';
import { formatNumber } from '../../common/utils';
import { useModelContext } from '../../context/ModelContext';
import { ItemSeriesProvider2 } from './DataProvider';
import NivoPie from './NivoPie';

const logger = new Logger("rating.RatingChartPie");

/**
 * Display the RatingValue's for specified items using a radar chart
 */
export function RatingChartPie(props:any) {
  const model = useModelContext().model;
  const { items, measure, ratingPeriod, classRow } = props;

  if (!items || items.length === 0) {
    return <></>;
  }

  logger.debug("Rendering: measure, items, period:", measure, items, ratingPeriod)

  const ratingPeriods = ratingPeriod ? [ratingPeriod] : model.getRatingPeriods();

  return (
    <div className={classRow}>
      { ratingPeriods.map(period =>
        <div className="col">
          <div className="chart-rating">
            <div className="text-center text-muted h4">{period.name}</div>
            <Pie items={items} period={period} />
          </div>
        </div>
      )}
    </div>
  );

  function Pie(props:any) {
    const { items, period } = props;
    const series = new ItemSeriesProvider2(model, measure, items, period);

    logger.debug("Pie: series", series);

    return (
      <NivoPie data={series.data} formatSlice={formatSlice} formatTooltip={formatNumber} />
    )
  }

  function formatSlice(arg:any) : string {
    let value;
    if (typeof arg === "number") {
      value = arg;
    } else {
      value = arg.rawValue;
    }
    return formatNumber(value);
  }
}

