import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import * as ModelService from "../../services/ModelService";
import { ChartType, RatingMeasure, RatingMeasureProps } from '../../types/RatingMeasure';
import chartTypes from "../chart/chart-types.json";
import DropdownBase from '../control/DropdownBase';

const logger = new Logger("cards.RatingChartCard");

function RatingChartCard(props: any) {
  const state = useModelContext();
  const className = props.className;
  const onEvent = props.onEvent;

  const measure:RatingMeasure = props.item;
  const [chartType, setChartType] = useState(measure.chartType);

  logger.debug("Rendering:", measure);

  // Render a Document view
  if (props.docView) return (
    <section className={className}>
      <h2>Chart Type: <ChartTypeDropdown selected={chartType} onChange={onChange} /></h2>
      <div className="mt-1">
        <ChartTypeTable />
      </div>
    </section>
  );

  // Render a Card view
  return (
    <Card className={className + " shadow text-level-2"}>
      <CardHeader className="border-0">
        <h2>Chart Type: <ChartTypeDropdown selected={chartType} onChange={onChange} /></h2>
      </CardHeader>
      <CardBody className="p-1">
        <ChartTypeTable />
      </CardBody>
    </Card>
  );

  function onChange(chartType: ChartType) {
    logger.debug("onChange started: chartType:", chartType);

    setChartType(chartType);

    const event = ModelService.updateItems<RatingMeasure>(
                  state.model, [measure.key], RatingMeasureProps.chartType, chartType);
    if (onEvent !== undefined) {
      onEvent(event);
    }

    logger.debug("onChange finished:", event);
  }

  function ChartTypeTable(props:any) {
    if (!chartType) {
      return <div>Choose a chart type for the {measure.name} measure</div>
    }
    return (
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{chartType.name}</td>
            <td>{chartType.description}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

export function ChartTypeDropdown(props:any) {
  let { selected, selectedKey, onChange } = props;
  const logger = new Logger("cards.ChartTypeDropdown");

  if (!selected && selectedKey) {
    selected = chartTypes.find(ct => ct.key === selectedKey);
  }

  return (
    <DropdownBase selected={selected} logger={logger.name}>
      { chartTypes.map(chartType =>
        <button className="btn-image item" onClick={(e) => onClick(e, chartType)} key={chartType.key}>
          {chartType.name}
        </button>
      )}
     </DropdownBase>
  );

  function onClick(e:any, chartType:any) {
    logger.debug("onClick: chartType=", chartType);
    if (onChange) {
      onChange(chartType);
    }
  }
}

export default RatingChartCard;