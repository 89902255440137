import React from "react";
import { Link } from "react-router-dom";
import { useModelContext } from "../context/ModelContext";
import { Model } from "../types/Model";

export function DashboardLink(props:any) {
  const model = useModelContext().model;
  const item = props.item || model.getItem(props.itemKey) || Model.root;
  const children = props.children;
  const className = props.className || "";

  if (!model.hasChildren(item.key)) {
    return <DocumentLink item={item} />
  }

  return (
    <Link to={{ pathname: "/dashboard/" + item.key }} className={className}>
      {children ? children : item.name}
    </Link>
  )
}

export function DocumentLink(props:any) {
  const model = useModelContext().model;
  const item = props.item || model.getItem(props.itemKey) || Model.root;
  const children = props.children;
  const className = props.className || "";

  if (!item) {
    return <>{children}</>
  }

  return (
    <Link to={{ pathname: "/document/" + item.key }} className={className}>
      {children ? children : item.name}
    </Link>
  )
}

export function NetworkLink(props:any) {
  const model = useModelContext().model;
  const item = props.item || model.getItem(props.itemKey) || Model.root;
  const children = props.children;
  const className = props.className || "";

  if (!item) {
    return <>{children}</>
  }

  return (
    <Link to={{ pathname: "/network/" + item.key }} className={className}>
      {children ? children : item.name}
    </Link>
  )
}

export function CanvasLink(props:any) {
  const model = useModelContext().model;
  const item = props.item || model.getItem(props.itemKey) || Model.root;
  const children = props.children;
  const className = props.className || "";

  if (!item) {
    return <>{children}</>
  }
  return (
    <Link to={{ pathname: "/canvas/" + item.key }} className={className}>
      {children ? children : item.name}
    </Link>
  )
}

export function TableLink(props:any) {
  const model = useModelContext().model;
  const item = props.item || model.getItem(props.itemKey) || Model.root;
  const children = props.children;
  const className = props.className || "";

  if (!item) {
    return <>{children}</>
  }
  return (
    <Link to={{ pathname: "/table/" + item.key }} className={className}>
      {children ? children : item.name}
    </Link>
  )
}
