import { Item } from "./Item";

/**
 * Have implemented a data driven typing system. Each Item in the model has a typeKey which
 * points to a TypeItem, which can then be used to drive different behaviours.
 * TypeItem defines some additional properties unique to a type.
 */
export interface TypeItem extends Item {
  /** Icon className (as per fontawesome) for items of this type */
  typeIcon: string;

  /** List of RatingMeasure keys that can be used for items of this type */
  measureKeys: string[];

  /** List of ChartType keys that override the default ChartType for corresponding measureKey */
  chartKeys: string[];
}

export type TypeItemProp = keyof TypeItem;

export class TypeItemProps {
  static typeIcon:TypeItemProp = "typeIcon";
  static measureKeys:TypeItemProp = "measureKeys";
  static chartKeys:TypeItemProp = "chartKeys";

  static ALL:string[] = [
    TypeItemProps.typeIcon,
    TypeItemProps.measureKeys,
    TypeItemProps.chartKeys,
  ]
}

