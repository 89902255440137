import React, { useState } from 'react';
import { Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { useModelContext } from "../../context/ModelContext";
import { useViewContext } from "../../context/ViewContext";
import ModelRow from "./ModelRow";

const logger = new Logger("component.ModelTable");

function ModelTable(props:any) {
  const model = useModelContext().model;
  const viewState = useViewContext();
  const rootKey = props.rootKey || "";
  const showRawScores = props.showRawScores;
  const showMaxScore = props.showMaxScore;

  const [hideDescription, onHideDescription] = useState(false);
  const [changeEvent, onChangeTable] = useState<any>();

  logger.debug("Rendering: rootKey=%s, hideDescription=%s: changeEvent:", 
                rootKey, hideDescription, changeEvent, viewState);

  return (
    <Table response="true" hover size="sm" responsive={true}>
      <THead className="thead-light"/>
      <TBody parentKey={rootKey} />
    </Table>
  );

  function THead(props:any) {
    const className = props.className;
    const classDesc = hideDescription ? "d-none" : "d-none d-lg-table-cell";
    const classSelect = viewState.showSelectCheckbox ? "rowmenu" : "d-none"
    const ratingPeriods = model.getRatingPeriods();

    return (
      <thead className={className}>
        <tr>
          <th className={classSelect}></th>
          { hideDescription
            ? <th className="name-desc">
                { 'Name & Description '}
                <DescriptionButton/>
              </th>
            : <th className="name">
                Name
              </th>
          }
          <th className={"description " + classDesc}>
            Description <DescriptionButton/>
          </th>
          <th className="rating">Rating</th>
          { showRawScores &&
            <th className="d-none d-lg-table-cell text-center" 
                colSpan={ratingPeriods.length + (showMaxScore ? 1 : 0)}>
              Raw Scores
            </th>
          }
          <th className="d-none d-sm-table-cell"></th>
        </tr>
        { showRawScores &&
          <tr className="d-none d-lg-table-row">
            <th className="pt-0" colSpan={ hideDescription ? 3 : 4}></th>
            { ratingPeriods.map(period =>
              <th className="pt-0 text-center" key={period.key}>{period.name}</th>
            )}
            { showMaxScore && 
              <th className="pt-0 text-center">Max</th>
            }
            <th></th>
          </tr>
        }
      </thead>
    );  
  }

  function TBody(props:any) {
    const parentKey = props.parentKey;

    // Log some render performance data
    const start = Date.now();
    const rows = generateRows(parentKey, 0, []);
    const duration = Date.now() - start;

    logger.debug("TBody: rendered %d rows in %d ms", rows.length, duration);

    return (
      <tbody>
        { rows }
      </tbody>
    );

    function generateRows(parentKey:string, level:number, results:any[]): any[] {
      // Get array of children sorted by type
      const items = model.childrenSorted(parentKey);

      for (let i=0; i < items.length;  i++) {
        const item = items[i];

        // Store level and reference to previous item (as per display sequence) for use by indent/outdent
        const viewStateItem = viewState.get(item.key);

        // Add new ModelRow to output
        if (viewStateItem.visible) {
          const hasViewableChildren = viewState.hasViewableChildren(model, item.key);
          results.push(
            <ModelRow item={item}
                      level={level}
                      expandable={hasViewableChildren}
                      onEvent={onChangeTable}
                      hideDescription={hideDescription}
                      showRawScores={showRawScores}
                      showMaxScore={showMaxScore}
                      key={item.key} />
          );
    
          // Recurse to next level if required
          if (viewStateItem.openTree && hasViewableChildren) {
            generateRows(item.key, level+1, results);
          }
        }
      }
  
      return results;
    }
  }

  function DescriptionButton(props:any) {
    const className = hideDescription ? "far fa-angle-right" : "far fa-angle-left";

    return (
      <button className="btn-image" onClick={() => onHideDescription(!hideDescription)}>
        <i className={className} />
      </button>
    );
  }
}

export default ModelTable;
