import numbro from 'numbro';

const dateFormat = require('date-format');

/**
 * Convert some text to sentence case
 */
export function toSentenceCase(text: string): string {
  return text.replace( /([A-Z])/g, " $1" ).trimLeft();
}

/**
 * Return an string array of enum labels that have been sentence cased
 * @param e An enum
 */
export function makeEnumNames(e: any): string[] {
  let labels = [];

  for (let item in e) {
    if (isNaN(Number(item))) {
        labels.push(toSentenceCase(item));
    }
  }
  return labels;
}

export function getRandomInt(min:number, max:number):number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function formatDate(date:number): string {
  if (isNaN(date)) {
    return "";
  }
  return dateFormat("yyyy-MM-dd hh:mm:ss.SSS", new Date(date));
}

const defaultNumberFormat = { average:true, mantissa:1, optionalMantissa:true }

export function formatNumber(value:any, format?:any) {
  if (isNaN(value) || value === null) {
    return "";
  }

  if (!format)
    format = defaultNumberFormat;

  return numbro(value).format(format);
}

export function lightOrDark(color:any) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 150) {
    return 'light';
  }
  else {
    return 'dark';
  }
}

export function isDark(color:any):boolean {
  return lightOrDark(color) === "dark";
}
