import Logger from "../common/Logger";
import { ItemStatus } from "./Item";
import { Model } from "./Model";
import { RatingMeasure } from './RatingMeasure';
import { RatingMeasureHelper } from './RatingMeasureHelper';
import { RatingValue } from "./RatingValue";

const logger = new Logger("types.RatingModel");

type ItemKey = string;
type MeasureKey = string;
type PeriodKey = string;
type ValueKey = string;

/**
 * Manages the calculation of aggregate values in a multi-dimensional "cube" of RatingValues.
 * Dimensions include:
 * - Item: which item is being rated (Capability, Project, Information asset, etc)
 * - Rating: what type of rating is being used
 * - Period: which period to the rating values belong
 */
export class RatingModel {
  /** Reference to the Model */
  private model: Model;

  /** Map of all RatingValue's that are associated with a particular Item */
  private itemKeyMap = new Map<ItemKey, Map<MeasureKey, RatingValue>>();

  /** For each RatingValue, hold an array that counts the number of items with each possible value */
  private countsMap = new Map<MeasureKey, number[]>();

  /** Map of maximum possible score for each item/measure combination */
  public itemMaxScoreMap = new Map<ItemKey, Map<MeasureKey, number>>();

  /** Some counters */
  public counters = {
    getValueSearchMillis: 0,
    getValueCreateMillis: 0,
    getValueCreateCount: 0,
    calculateItemCount: 0,
    calculateItemMillis: 0,
    calculateForRatingCount: 0,
    calculateForRatingMillis: 0,
    calculateMaxScoreCount: 0,
    calculateMaxScoreMillis: 0,
    itemsNoMeasures: 0,
  }

  constructor(model:Model) {
    this.model = model;
  }

  /**
   * Set a RatingValue into the model
   * @param value 
   * @param isReload 
   */
  public setValue(value:RatingValue, isReload:boolean) {
    // Store the RatingValue in maps keyed by itemKey and measureKey within that
    let valueMap = this.itemKeyMap.get(value.itemKey);
    if (valueMap === undefined) {
      valueMap = new Map<MeasureKey,RatingValue>();
      this.itemKeyMap.set(value.itemKey, valueMap);
    }
    valueMap.set(value.key, value);

    // Recalculate all parents (unless a reload in progress)
    if (!isReload) {
      this.calculateUp(value.itemKey);
    }
  }
  
  /**
   * Remove the specified RatingValue from the rating model
   * @param value 
   */
  public removeValue(value:RatingValue) {
    const values = this.itemKeyMap.get(value.itemKey);
    if (values !== undefined) {
      values.delete(value.key);
    }
  }

  public zeroCounters() {
    this.counters.getValueCreateCount = 0;
    this.counters.getValueCreateMillis = 0;
    this.counters.getValueSearchMillis = 0;
    this.counters.calculateItemCount = 0;
    this.counters.calculateItemMillis = 0;
    this.counters.calculateForRatingCount = 0;
    this.counters.calculateForRatingMillis = 0;
    this.counters.itemsNoMeasures = 0;
  }

  /**
   * Get all RatingValue's for the specified item
   * @param itemKey
   */
  public getValuesForItem(itemKey:string) : RatingValue[] {
    const valueMap = this.itemKeyMap.get(itemKey);
    return (valueMap !== undefined) ? Array.from(valueMap.values()) : [];
  }

  /**
   * Search the RatingModel for a RatingValue with the specified attributes
   * @param itemKey 
   * @param measureKey 
   * @param periodKey 
   */
  public getValue(itemKey:string, measureKey:string, periodKey:string, create:boolean = false): RatingValue {
    let start = Date.now();

    // Find existing value
    const valueMap = this.itemKeyMap.get(itemKey);
    if (valueMap !== undefined) {
      for (const value of valueMap.values()) {
        if (value.periodKey === periodKey && value.measureKey === measureKey) {
          this.counters.getValueSearchMillis += (Date.now() - start);
          return value;
        }
      }
    }
    this.counters.getValueSearchMillis += (Date.now() - start);

    // Not found - but we can create if necessary
    start = Date.now();
    let result:any;
    if (create) { 
      const ratingValue = this.newRatingValue(itemKey, measureKey, periodKey);
      this.setValue(ratingValue, true);
      result = ratingValue;

      this.counters.getValueCreateCount++;
    }

    this.counters.getValueCreateMillis += (Date.now() - start);
    return result;
  }

  /**
   * Calculate aggregate RatingValue's for the specified item and all children (recursively)
   * @param itemKey 
   */
  public calculateDown(itemKey:ItemKey) : number {
    logger.trace("calculateDown: started %s", itemKey);

    const start = Date.now();
    let count = 0;

    // Recursively calculate children first
    const childKeys = this.model.childrenKeys(itemKey);
    for (const key of childKeys) {
      count += this.calculateDown(key);
    }

    // Calculate this item
    this.calculateItem(itemKey);
    count++;

    const ms = Date.now() - start;
    logger.trace("calculateDown: finished %s in %d ms (%d items)", itemKey, ms, count);
    return count;
  }

  /**
   * Calculate aggregate RatingValue's for the specified item and all children (recursively)
   * @param itemKey 
   */
  private calculateUp(itemKey:ItemKey) : number {
    logger.trace("calculateUp: started %s", itemKey);

    const start = Date.now();
    let count = 0;

    // Recursively calculate children first
    const item = this.model.getItem(itemKey);
    if (this.model.isRateable(item)) {
      // Calculate this item
      this.calculateItem(itemKey);
      count++;

      // Now calculate it's parent
      count += this.calculateUp(item.parentKey);
    }

    const ms = Date.now() - start;
    logger.trace("calculateUp: finished %s in %d ms (%d items)", itemKey, ms, count);
    return count;
  }

  /**
   * Calculate aggregate RatingValue's for the specified item
   * @param itemKey 
   */
  private calculateItem(itemKey:ItemKey) {
    if (this.model.isRateableKey(itemKey)) {
      const start = Date.now();

      logger.trace("calculateItem: started %s", itemKey);

      try {
        // Not all types may be setup with ratings
        const item = this.model.getItem(itemKey);

        const measureKeys = this.model.getItemType(item).measureKeys;
        if (measureKeys !== undefined) {
          const ratingPeriods = this.model.getRatingPeriods();
          const childKeys = this.model.childrenKeys(itemKey).filter(key => this.model.isRateableKey(key));

          // Calculate RatingValue's for all RatingPeriod's and RatingMeasure's 
          for (const period of ratingPeriods) {
            for (const measureKey of measureKeys) {
              this.calculateForRating(itemKey, measureKey, period.key, childKeys);
            }
          }
        } else {
          this.counters.itemsNoMeasures++;
        }
      } catch (e) {
        logger.error("calculateItem: Error itemKey='%s':", itemKey, e);
        throw e;
      }

      const duration = Date.now() - start;
      this.counters.calculateItemCount++;
      this.counters.calculateItemMillis += duration;

      logger.trace("calculateItem: finished %s in %d ms", itemKey, duration);
    }
  }

  /**
   * Aggregate RatingValue's all immediate children of this item, by RatingMeasure and RatingPeriod
   * @param itemKey 
   * @param measureKey 
   * @param periodKey 
   * @param childKeys 
   */
  private calculateForRating(itemKey:ItemKey, measureKey:MeasureKey, periodKey:PeriodKey, childKeys:ItemKey[]) {
    logger.trace("calculateForRating: started %s, measureKey=%s, periodKey=%s", itemKey, measureKey, periodKey);

    // Calculate (recursively) all child ratings
    // e.g. a Capability Rating is comprised of a separate rating for People, Process, Technology and Data
    const childMeasureKeys = this.model.childrenKeys(measureKey);
    for (const childMeasureKey of childMeasureKeys) {
      this.calculateForRating(itemKey, childMeasureKey, periodKey, childKeys);
    }

    const start = Date.now();

    // Get RatingValue to amend, or create new a TRANSIENT RatingValue that contains a calculated value. 
    // These exist only in the RatingModel and are not present in the Model itself i.e. they won't be saved
    const ratingValue = this.getValue(itemKey, measureKey, periodKey, true);
    // if (this.model.has(ratingValue.key)) {
    //   logger.trace("calculateForRating: exiting %s, measureKey=%s, periodKey=%s, ratingValue.status=%s", 
    //                 itemKey, measureKey, periodKey, ratingValue.status);
    //   return;
    // }

    try {
      const helper = new RatingMeasureHelper(this.model, measureKey);

      const childMeasures = childMeasureKeys.map(childMeasureKey => this.getValue(itemKey, childMeasureKey, periodKey));
      const childRatingValues = childKeys.map(childItemKey => this.getValue(childItemKey, measureKey, periodKey));
      this.calculateMaxScore(helper, ratingValue, childMeasures, childRatingValues);

      // Calculate for all child rating types of the specified measureKey (but only for this itemKey)
      if (childMeasures.length > 0) {
        // Calculate for child rating types
        ratingValue.value = this.calculateValue(helper, childMeasures);

        logger.trace("calculateForRating: setting %s, measureKey=%s, periodKey=%s, ratingValue.value=%s", 
                      itemKey, measureKey, periodKey, ratingValue.value);
      } 
      
      // Calculate for all children of the specified itemKey (but only for this measureKey)
      if (childRatingValues.length > 0) {           
        // Calculate aggregations for min, max, average aggregates across child items
        if (helper.getCalcKey() !== "MATRIX") {
          ratingValue.value = this.calculateValue(helper, childRatingValues);
        }
  
        // Update the counts
        this.calculateCounts(helper, ratingValue, childRatingValues);
      } 
    } catch (e) {
      logger.error("calculateForRating: itemKey=%s, measureKey=%s, periodKey=%s:",
                    itemKey, measureKey, periodKey, ratingValue, e);
    }

    const duration = Date.now() - start;
    this.counters.calculateForRatingCount++;
    this.counters.calculateForRatingMillis += duration;

    logger.trace("calculateForRating: finished %s in %d ms", itemKey, duration);
  }

  private calculateValue(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    if (ratingValues === undefined || ratingValues.length === 0) {
      return 0;
    }

    const ratingCalcKey = helper.getCalcKey();
    switch (ratingCalcKey) {
      case "AVG": 
        return this.calcAVERAGE(helper, ratingValues);

      case "MIN": 
        return this.calcMIN(helper, ratingValues);

      case "MAX": 
        return this.calcMAX(helper, ratingValues);

      case "TOTAL": 
        return this.calcTOTAL(helper, ratingValues);

      case "MATRIX":
        return helper.lookupMatrixValue(ratingValues);              

      case "PERCENT":
        return this.calcPERCENT(helper, ratingValues);

      case "WSJF":
        return this.calcWSJF(helper, ratingValues);
      }

    return 0;
  }

  private calcAVERAGE(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    let total=0, count=0;
    for (const ratingValue of ratingValues) {
      if (ratingValue && ratingValue.value !== 0) {
        total += ratingValue.value;
        count++;
      }
    }
    const average = (count === 0) ? 0 : (total / count);
    return average;
  }

  private calcMIN(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    let result = Infinity;
    for (const ratingValue of ratingValues) {
      if (ratingValue && ratingValue.value < result) {
        result = ratingValue.value;
      }
    }
    return result;
  }

  private calcMAX(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    let result = -Infinity;
    for (const ratingValue of ratingValues) {
      if (ratingValue && ratingValue.value > result) {
        result = ratingValue.value;
      }
    }
    return result;
  }

  private calcTOTAL(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    let total = 0;
    for (const ratingValue of ratingValues) {
      if (ratingValue) {
        total += ratingValue.value;
      }
    }
    return total;
  }

  private calcPERCENT(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    const total = this.calcTOTAL(helper, ratingValues);
    return total;
  }

  private calcWSJF(helper:RatingMeasureHelper, ratingValues:RatingValue[]) : number {
    let total = 0;
    for (const ratingValue of ratingValues) {
      if (ratingValue) {
        total += ratingValue.value;
      }
    }
    return total;
  }

  /**
   * 
   * @param helper 
   * @param ratingValue 
   * @param childValues RatingValues for all child Items of ratingValue.itemKey, all for same RatingMeasure
   */
  private calculateCounts(helper:RatingMeasureHelper, ratingValue:RatingValue, childValues:RatingValue[]) {
    if (childValues === undefined || childValues.length === 0) {
      return;
    }

    // Create array of counts, and store in map for the ratingValue
    const size = helper.getScale().scale.length;
    const counts:number[] = [size];
    for (let i=0; i < size; i++) {
      counts[i] = 0;
    }
    this.countsMap.set(ratingValue.key, counts);

    // Update the counts
    for (const childValue of childValues) {
      if (childValue !== undefined && childValue.value !== 0) {
        const childCounts = this.countsMap.get(childValue.key);
        if (childCounts !== undefined) {
          for (let i=0; i < size; i++) {
            counts[i] += childCounts[i];  
          }
        } 
        
        else if (helper.hasScaleItems()) {
          const index = helper.findScaleItemIndex(childValue.value);
          if (index >= 0) {
            counts[index]++;
          } else {
            logger.warn("calculateCounts: Cannot find value=%f in:", childValue.value, helper.getScale().scale);
          }
        }
      }
    }
  }

  public getCounts(value:RatingValue) : number[] | undefined {
    return (value && value.key) ? this.countsMap.get(value.key) : undefined;
  }

  private getMaxScaleValue(measureKey:string) : number {
    const measure = this.model.getItem<RatingMeasure>(measureKey);
    const scale = measure.ratingScale.scale;
    if (scale && scale.length > 0) {
      return scale[scale.length-1].value;
    }
    return 0;
  }
  
  private calculateMaxScore(helper:RatingMeasureHelper, ratingValue:RatingValue, childMeasures:RatingValue[], childValues:RatingValue[]) {
    // if (childValues === undefined || childValues.length === 0) {
    //   return;
    // }

    if (ratingValue.periodKey !== this.model.getRatingPeriods()[0].key)
      return;

    let maxValue = 0;

    if (childValues.length > 0) {
      for (const childValue of childValues) {
        if (childValue) {
          maxValue += this.getMaxScore(childValue.itemKey, childValue.measureKey);
        }
      }
    } else if (childMeasures.length > 0) {
      for (const childMeasure of childMeasures) {
        if (childMeasure) {
          maxValue += this.getMaxScaleValue(childMeasure.measureKey);
        }
      }
    } else {
      maxValue = this.getMaxScaleValue(ratingValue.measureKey);
    }

    this.setMaxScore(ratingValue.itemKey, ratingValue.measureKey, maxValue);

    // if (ratingValue.itemKey === "TM-0001" || ratingValue.itemKey === "TM-1000") {
    //   logger.debug("calculateMaxScore: itemKey=%s, measureKey=%s, periodKey=%s, value=%f, maxValue=%f, maxScore=%f", 
    //                 ratingValue.itemKey, 
    //                 ratingValue.measureKey, 
    //                 ratingValue.periodKey, 
    //                 ratingValue.value,
    //                 maxValue, 
    //                 this.getMaxScore(ratingValue.itemKey, ratingValue.measureKey),
    //                 childMeasures,
    //                 childValues);
    // }
  }

  private setMaxScore(itemKey:ItemKey, measureKey:MeasureKey, value:number) {
    let score = 0;
    let scoresMap = this.itemMaxScoreMap.get(itemKey);
    if (scoresMap !== undefined) {
      score = scoresMap.get(measureKey) || 0;
    } else {
      scoresMap = new Map<MeasureKey,number>();
      this.itemMaxScoreMap.set(itemKey, scoresMap);
    }

    const newScore = score + value;
    scoresMap.set(measureKey, newScore);

    this.counters.calculateMaxScoreCount++;

    return newScore;
  }

  public getMaxScore(itemKey:ItemKey, measureKey:MeasureKey) : number {
    let maxScore;

    // See if we have calculated a max score
    const scoresMap = this.itemMaxScoreMap.get(itemKey);
    if (scoresMap) {
      maxScore = scoresMap.get(measureKey);
    }

    // If not, then use max scale value
    if (maxScore === undefined) {
      maxScore = this.getMaxScaleValue(measureKey);
    }
 
    return maxScore || 0;
  }

  /**
   * Factory method to create a new RatingValue with default values. The generated key is of the
   * form "XX-{uuid}" i.e. the prefix "XX-" followed by a generated unique identifier.
   * @param itemKey
   * @param measureKey 
   * @param periodKey 
   */
  private newRatingValue(itemKey:string, measureKey:string, periodKey:string) : RatingValue {
    const parentKey = this.model.getRatingValueFolderKey();
    const value:RatingValue = {
      key: this.model.newKey(parentKey, Model.KeyPrefixRatingValue),
      name: "",
      description: "",
      parentKey: parentKey,
      itemKey: itemKey,
      measureKey: measureKey,
      periodKey: periodKey,
      value: 0,
      typeKey: this.model.getTypeRatingValueKey(),
      sortOrder: 0,
      status: ItemStatus.TRANSIENT,
      modifiedDate: Date.now(),
    }

    return value;
  }
}