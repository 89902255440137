import React, { useState } from 'react';
import { Table } from 'reactstrap';
import Logger from "../../common/Logger";
import { formatDate } from "../../common/utils";
import { useModelContext } from '../../context/ModelContext';
import * as ModelService from "../../services/ModelService";
import { Item, ItemProps } from "../../types/Item";
import { TypeItemProps } from '../../types/TypeItem';
import { DocumentLink } from '../../views/ViewLinks';
import Editable from '../control/Editable';
import ItemDropdown from '../control/ItemDropdown';
import ItemPickerTree from '../control/ItemPickerTree';
import { ViewContextProvider } from '../../context/ViewContext';

const logger = new Logger("component.ItemDetailsEditor");

function ItemDetailsEditor(props:any) {
  const model = useModelContext().model;
  const className = props.className;
  const onEvent = props.onEvent;
  const searchKeys = [ItemProps.key, ItemProps.Name];
  const [item, setItem] = useState<Item>(props.item);

  logger.debug("Rendering: key=%s", item?.key, item);

  return (
    <ViewContextProvider name="ItemDetailsEditor">
      <Table className={"align-items-center " + className} responsive>
        <thead className="thead-light">
          <tr>
            <th style={{ width: "6rem" }}>Property</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="thead text-muted">Key</td>
            <td>{item.key}</td>
          </tr>
          <tr>
            <td className="thead text-muted">Code</td>
            <td>
              <Editable item={item} property={ItemProps.code} onEvent={onEvent} />
            </td>
          </tr>
           <tr>
            <td className="thead text-muted">
              <DocumentLink item={model.getItem(item.parentKey)}>Parent</DocumentLink>
            </td>
            <td className="text-nowrap">
              <ItemPickerTree
                selected={model.getItem(item.parentKey)}
                searchKeys={searchKeys}
                format={formatItem}
                onChange={(selectedItem: Item) => onChangeItem(item, ItemProps.parentKey, selectedItem.key)}
                className="text-wrap" />
            </td>
          </tr>
          { model.isType(item)
            ? <tr>
              <td className="thead text-muted">Type Icon</td>
              <td className="text-nowrap">
                <Editable item={item} property={TypeItemProps.typeIcon} onEvent={onEvent} />
              </td>
            </tr>
            : <tr>
              <td className="thead text-muted">
                <DocumentLink item={model.getItem(item.typeKey)}>Type</DocumentLink>
              </td>
              <td className="text-nowrap">
                <ItemDropdown
                  options={model.sortByName(model.getTypeItems())}
                  selected={model.getItem(item.typeKey)}
                  searchKeys={searchKeys}
                  format={formatItem}
                  onChange={(selectedItem: Item) => onChangeItem(item, ItemProps.typeKey, selectedItem.key)}
                  className="text-wrap" />
              </td>
            </tr>
          }
          <tr>
            <td className="thead text-muted">Status</td>
            <td>{item.status}</td>
          </tr>
          <tr>
            <td className="thead text-muted">Modified Date</td>
            <td>{formatDate(item.modifiedDate)}</td>
          </tr>
          {item.modifiedBy &&
            <tr>
              <td className="thead text-muted">Modified By</td>
              <td>{item.modifiedBy}</td>
            </tr>
          }
        </tbody>
      </Table>
    </ViewContextProvider>
  );

  function formatItem(item:Item | undefined) {
    return (item === undefined) ? "" : item.name;
  }

  function onChangeItem(item:Item, property:keyof Item, value:any) {
    logger.debug("onChangeItem: item.key=%s, property=%s, value=%s", item.key, property, value);

    const event = ModelService.updateItems(model, [item.key], property, value);
    setItem(model.getItem(item.key));

    if (onEvent !== undefined) {
      logger.debug("onChangeItem: calling onEvent:", event);
      onEvent(event);
    }
  }
}

export default ItemDetailsEditor;