import React from 'react';
import { RatingScale, RatingScaleItem } from '../../types/RatingMeasure';
import { RatingMeasureUtils } from '../../types/RatingMeasureHelper';

function RatingScaleBar(props:any) {
  const item = props.item;
  const value = props.value || -1;
  const targetValue = props.targetValue || -1;
  const className = props.className;

  // Not every item has a ratingScale, so just return null for now
  let scale:RatingScaleItem[] = props.scale;
  if (scale === undefined) {
    let ratingScale:RatingScale = item.ratingScale;
    if (ratingScale === undefined) {
      return <></>;
    }
    scale = ratingScale.scale;
  }

  // Index of current value
  const valueIndex = RatingMeasureUtils.findScaleItemIndex(scale, value);
  const targetIndex = RatingMeasureUtils.findScaleItemIndex(scale, targetValue);
  const total = (scale.length === 0) ? 0 : scale[scale.length-1].value;

  return (
    <>
    <div className="justify-content-center">
      <div className={"progress progress-marker " + className}>
        { scale.map((item,i) => {
            const value = (i === 0) ? item.value : item.value - scale[i-1].value;
            const width = value/total * 100;
            const style = {
              width: width + "%",
              backgroundColor: item.color
            }
            const title = item.name + " (" + item.value + ")";

            return (
              <div key={item.value} className="progress-bar progress-marker" role="progressbar" style={style} title={title}>
                { i === valueIndex && 
                  <i className="far fa-circle"/>
                }
                { i === targetIndex && 
                  <i className="far fa-bullseye"/>
                }
              </div>
            )
          })
        }
      </div>
    </div>
    </>
  );
}

export default RatingScaleBar;
