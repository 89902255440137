import React from 'react';
import Logger from "../../common/Logger";
import { isDark } from '../../common/utils';
import { useModelContext } from '../../context/ModelContext';
import { Item } from '../../types/Item';
import { RatingMeasureHelper, RatingMeasureUtils } from "../../types/RatingMeasureHelper";
import { DocumentLink } from '../../views/ViewLinks';

const logger = new Logger("rating.RiskMatrixCard");

function RiskMatrixCard(props:any) {
  const model = useModelContext().model;
  const { items, measure } = props;

  // Note that RatingMatrix is a type of RatingCalc
  const ratingModel = model.getRatingModel();
  const ratingHelper = new RatingMeasureHelper(model, measure);
  const ratingCalc = ratingHelper.getMatrix();

  logger.debug("Rendering:", ratingCalc);

  // Some constants used to render
  const xaxisKey = ratingHelper.getMatrix().xaxisKey;
  const yaxisKey = ratingHelper.getMatrix().yaxisKey;
  const xscale = ratingHelper.getMatrixXScaleItems();
  const yscale = ratingHelper.getMatrixYScaleItems();
  const styleCell = { width: 100/xscale.length + "%" }

  return (
    <div className="risk-matrix align-items-center mt-2">
      <div className="row text-muted header">
        <div className="col-2">
          <DocumentLink item={model.getItem(ratingCalc.yaxisKey)} />
        </div>
        <div className="col-10 text-center">
          <DocumentLink item={model.getItem(ratingCalc.xaxisKey)} />
        </div>
      </div>
      <div className="row text-muted mt-1">
        <div className="col"/>
        { ratingHelper.sortAscending(xscale).map(col => 
          <div className="col text-center" style={styleCell} key={col.value}>
            {col.name}
          </div>
        )}
      </div>
      <div className="body mt-1">
        { ratingHelper.sortDescending(yscale).map(yitem => 
          <div className="row" key={yitem.value}>
            <div className="col text-muted pt-2">
              {yitem.name}
            </div>
            { ratingHelper.sortAscending(xscale).map(xitem =>
              <TCell xitem={xitem} yitem={yitem} key={xitem.value} />
            )}
          </div>
        )}
      </div>
    </div>
  );

  function TCell(props:any) {
    const { xitem, yitem } = props;
    const scaleItem = ratingHelper.getMatrixScaleItem(xitem, yitem);
    const style = { backgroundColor: scaleItem?.color }
    const classText = isDark(scaleItem?.color) ? " text-white" : "";

    const period = model.getRatingPeriodForDate(Date.now());

    return (
      <div className="col col-cell">
        <div className={"cell text-center" + classText} style={style}>
          <div>{ scaleItem?.name }</div>
          { filteredItems().map(item =>
            <div className="pill align-middle" key={item.key}>
              <DocumentLink className="text-nowrap" item={item}>{item?.code}</DocumentLink>
            </div>
          )}
        </div>
      </div>
    )

    /**
     * Return a list of items that fit in col x, row y
     */
    function filteredItems(): Item[] {
      const filtered:Item[] = [];

      for (const item of items) {
        const xvalue = ratingModel.getValue(item.key, xaxisKey, period.key).value;
        const yvalue = ratingModel.getValue(item.key, yaxisKey, period.key).value;

        const x1 = RatingMeasureUtils.findScaleItem(xscale, xvalue);
        const y1 = RatingMeasureUtils.findScaleItem(xscale, yvalue);

        if (xitem.value === x1?.value && yitem.value === y1?.value) {
          filtered.push(item);
        }
      }

      return filtered;
    }
  }
}

export default RiskMatrixCard;