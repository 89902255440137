import Logger from "../../common/Logger";
import { Item } from "../../types/Item";
import { Model } from "../../types/Model";
import { ViewState } from "../../context/ViewState";

const logger = new Logger("chart.NetworkDataProvider");

export interface Node {
  key: string;
  item: Item,
  size: number;
  color: number;
  level: number;
}

export interface Link {
  source: string;
  target: string;
  width: number;
  linkType: LinkType;
}

export type LinkType = "Parent" | "Association" | "Type" | "Measure";

export class NetworkDataProvider {
  private model: Model;
  private viewState: ViewState;
  private nodeMap:Map<string,Node> = new Map<string,Node>();

  public nodes:Node[] = [];
  public links:Link[] = [];
  public maxLevel:number = 0;

  constructor(model:Model, viewState:ViewState) {
    this.model = model;
    this.viewState = viewState;
  }

  public getNode(key:string) {
    return this.nodeMap.get(key);
  }

  public addChildren(key:string): NetworkDataProvider {
    // Create nodes for item specified by key, all children and items that link to
    const keys = this.addNodes(key, (key === "" ? 0 : 1));

    // Create links
    for (const key of keys) {
      this.addLinks(key);
    }

    logger.trace("addChildren: key=%s, added %d nodes, %d links:", 
                  key, this.nodes.length, this.links.length, this.nodes, this.links, this.viewState);

    return this;
  }

  private addNodes(key:string, level:number=1, keys:string[]=[]): string[] {
    // Create node
    if (this.model.has(key) && !this.nodeMap.has(key)) {
      keys.push(key);
      this.addNode(key, level);

      // logger.debug("addNodes: Added node:", node, this.viewState.get(key));
    }

    if ((key === "" && level <= 1) || this.viewState.isOpenTree(key)) {
      const relatedKeys = this.model.relatedKeys(key);
      for (const ckey of relatedKeys) {
        if (!this.nodeMap.has(ckey) && !this.model.isRatingValueKey(ckey)) {
          this.addNodes(ckey, level+1, keys);
        }
      }
    }

    return keys;
  }

  private addLinks(key:string) {
    const item = this.model.getItem(key);

    this.addLink(item.key, item.parentKey, "Parent");
    this.addLink(item.key, item.typeKey, "Type");

    if (item.links) {
      for (const targetKey of item.links) {
        this.addLink(key, targetKey, "Association");
      }
    }

    if (this.model.isType(item)) {
      this.addMeasureLinks(item);
    }
  }

  private addNode(key:string, level:number) : Node {
    const item = this.model.getItem(key);
    const node:Node = {
      key: key,
      item: item,
      size: Math.trunc(12 - Math.sqrt(level*4)),
      color: 0,
      level: level
    }
    this.nodeMap.set(key, node);
    this.nodes.push(node);

    if (level > this.maxLevel) {
      this.maxLevel = level;
    }
    return node;
  }

  private addLink(sourceKey:string, targetKey:string, linkType:LinkType) {
    if (targetKey && targetKey !== "" && this.nodeMap.has(targetKey)) {
      this.links.push({
        source: sourceKey,
        target: targetKey,
        width: 1,
        linkType: linkType,
      });
    }
  }

  private addMeasureLinks(typeItem:any) {
    const measureKeys = typeItem.measureKeys || [];
    for (const measureKey of measureKeys) {
      this.addLink(typeItem.key, measureKey, "Measure");
    }
  }
}