import * as d3Shape from 'd3-shape';
import { LineSeries } from 'react-vis';
import Logger from "../../common/Logger";

const logger = new Logger("chart.BezierLineSeries");

export class BezierLineSeries extends LineSeries {
  _renderLine(data, x, y, curve, getNull) {
    let link = d3Shape.linkHorizontal()
                      .source((d) => d[0])
                      .target((d) => d[1])
                      .x(x)
                      .y(y);
    let result = link(data);

    logger.trace("_renderLine: data, x1, y1, x2, y2, result", data, x(data[0]), y(data[0]), x(data[1]), y(data[1]), result);

    return result;
  }
}
