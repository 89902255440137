import React, { createContext, useContext, useState } from "react";
import Logger from "../common/Logger";
import { useModelContext } from "./ModelContext";
import { ViewState } from "./ViewState";

const logger = new Logger("context.ViewContext");

const initialViewState = new ViewState();

const ViewContext = createContext<ViewState>(initialViewState);

export function useViewContext() {
  return useContext(ViewContext);
}

let contextId = 0;

export function ViewContextProvider(props:any) {
  const model = useModelContext().model;
  const name = props.name || "";
  const rootKey = props.rootKey || "";
  const level = props.level || 1;

  const [id] = useState(++contextId);
  const [event, setEvent] = useState<any>();
  const [viewState, setViewState] = useState(new ViewState(initialViewState, {...props, onEvent:setEvent}));

  React.useEffect(() => {
    logger.debug("[%s:%d] Initialising ViewState for %s, openLevel => %d:", name, id, rootKey, level, viewState);

    viewState.setOpenLevel(model, rootKey, level, true);
    setViewState(vs => new ViewState(vs));

    logger.debug("[%s:%d] Initialised ViewState for %s:", name, id, rootKey, viewState, props, event);
  }, [id]);

  // Respond to changes in the search results
  React.useEffect(() => {
    if (event) {
      logger.debug("[%s:%d] Event detected:", name, id, event);
      setViewState(vs => new ViewState(vs));
    }
  }, [id,event]);

  return (
    <ViewContext.Provider value={viewState}>
      { props.children }
    </ViewContext.Provider>
  );
}
