import React from 'react';
import Logger from '../../common/Logger';
import { formatNumber } from '../../common/utils';
import { useModelContext } from '../../context/ModelContext';
import { PeriodSeriesProvider } from './DataProvider';
import NivoBar from './NivoBar';

const logger = new Logger("rating.RatingChartBar");

/**
 * Display the RatingValue's for specified items using a radar chart
 */
export function RatingChartBar(props:any) {
  const model = useModelContext().model;
  const { items, measure, stacked } = props;

  if (!items || items.length === 0) {
    return <></>;
  }
  
  const series = new PeriodSeriesProvider(model, measure, items);
  const factor = series.getScaleFactor();
  const hasScale =  series.hasScaleItems();
  const scaleValues = hasScale ? series.getScaleValues() : null;

  logger.debug("Rendering: series, scaleValues:", series, scaleValues);
 
  return (
    <div className="chart-rating">
      <NivoBar
        data={series.data}
        keys={series.keys}
        indexBy="period"
        enableLabel={!hasScale}
        formatValue={formatValue}
        formatTooltip={formatTooltip}
        formatYAxis={formatYAxis}
        gridYValues={scaleValues}
        groupMode={stacked ? "stacked" : "grouped" }
        layout="vertical"
      />
   </div>
  );

  function formatValue(arg:any) : string {
    let value;
    if (typeof arg === "number") {
      value = arg;
    } else {
      value = arg.data[arg.id];
    }
    return formatNumber(value);
  }

  function formatTooltip(value:number) : string {
    if (series.hasScaleItems()) {
      return series.getScaleItemName(value);
    }
    return formatNumber(value);
  }

  function formatYAxis(value:number) : string {
    if (scaleValues) {
      const val = scaleValues.find(sv => (Math.round(sv * factor) === value));
      return (!val || isNaN(val)) ? "" : formatTooltip(val);
    }
    return formatNumber(value);
  }
}

